import { sample } from 'effector'
import { regenerateItineraryModel } from '~/features/regenerate-itinerary'
import { roomDetailsOpened } from '~/features/trip-booking/select-room/model'
import { tripIdeaModel } from '~/entities/destination/model'
import { hotelsForTripModel } from '~/entities/hotel'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { bookingInitiated, destinationViewed } from './'

sample({
  clock: destinationViewed,
  fn: (destination) => ({
    name: eventNames.visitedTripPage,
    properties: {
      trip: destination?.name,
      id: destination?.id,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationViewed,
  fn: (destination) => ({
    name: eventNames.tripVisited,
    properties: {
      trip: destination?.name,
      id: destination?.id,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: tripIdeaModel.$$manageTripIdeaFactory.selectedDestination,
  fn: () => ({
    name: eventNames.tripsTripClicked,
  }),
  target: analyticsModel.track,
})

sample({
  clock: regenerateItineraryModel.regenerateRequested,
  fn: ({ id, fields }) => ({
    name: eventNames.initiatedItineraryRegenerate,
    properties: {
      id,
      ...fields,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: hotelsForTripModel.$$manageHotelDetailsFactory.updateHotelDetails,
  fn: () => ({
    name: eventNames.tripHotelVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: roomDetailsOpened,
  fn: () => ({
    name: eventNames.tripRoomVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: bookingInitiated,
  fn: () => ({
    name: eventNames.tripBookingClicked,
  }),
  target: analyticsModel.track,
})
