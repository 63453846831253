import { createFactory } from '@withease/factories'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { Gate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset, spread } from 'patronum'
import {
  convertToDataBodyForSearchFlight,
  generatePassengers,
} from '~/features/search-flights/lib'
import {
  getHotelNearestAirport,
  searchFlights,
} from '~/features/search-flights/model/search-flights/api'
import { chooseTripDatesModel } from '~/features/trip-booking/choose-trip-dates'
import { tripIdeaModel } from '~/entities/destination/model'
import { hotelsForTripModel } from '~/entities/hotel'
import { tripInfoModel } from '~/entities/trip-info'

import { LocalStorageKeys } from '~/shared/config'
import { formatToHyphenDate } from '~/shared/lib/date'
import { bridge } from '~/shared/lib/factory'
import { ManageLocalStorageForSearchFlights } from '..'
import { Airport, DefaultValues } from '../../types'
import { GetUserPosition } from '../get-user-position'
export type SearchFlightsFactory = ReturnType<typeof searchFlightsFactory>

export const searchFlightsFactory = createFactory(
  ({
    gate,
    $$manageLocalStorageForSearchFlights,
    $$getUserPositionFactory,
  }: {
    gate: Gate<DefaultValues>
    $$manageLocalStorageForSearchFlights: ManageLocalStorageForSearchFlights
    $$getUserPositionFactory: GetUserPosition
  }) => {
    const searchFlightsFx = createEffect(searchFlights)
    const getHotelNearestAirportFx = createEffect(getHotelNearestAirport)
    const adultsCountChanged = createEvent<number>()
    const childrenCountChanged = createEvent<number>()
    const fromAirportChanged = createEvent<Airport | null>()
    const updateError = createEvent<string>()

    const resetAdultsCount = createEvent()
    const resetChildrenCount = createEvent()

    const $loading = searchFlightsFx.pending
    const $adultsCount = restore(adultsCountChanged, 1)
    const $childrenCount = restore(childrenCountChanged, 0)
    const $fromAirport = restore<Airport | null>(fromAirportChanged, null)
    const $startDate = createStore<Date>(new Date())
    const $endDate = createStore<Date>(new Date())
    const $error = restore(updateError, '')

    const $passengers = combine(
      tripInfoModel.$adultsAmount,
      tripInfoModel.$childrenAmount,
      generatePassengers,
    )

    bridge(() => {
      sample({
        clock: tripIdeaModel.$$getTripIdeaByIdFactory.getTripIdeaByIdCompleted,
        source: gate.state,
        fn: (tripIdea) => {
          const adults = tripIdea?.adultsAmount ?? 1
          const children = tripIdea?.childrenAmount ?? 0
          return { adults, children }
        },
        target: spread({
          targets: {
            adults: adultsCountChanged,
            children: childrenCountChanged,
          },
        }),
      })

      sample({
        clock: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
        filter: (hotel) => !!hotel,
        fn: (hotel) => ({
          lat: hotel?.latitude,
          _long: hotel?.longitude,
          name: hotel?.countryName,
        }),
        target: getHotelNearestAirportFx,
      })

      sample({
        clock: getHotelNearestAirportFx.doneData,
        fn: ({ data }) => data!.airport!.iataCode!,
        target: $$manageLocalStorageForSearchFlights.$toAirportCode,
      })
    })

    bridge(() => {
      sample({
        clock: [
          $fromAirport,
          $$manageLocalStorageForSearchFlights.$flightType,
          getHotelNearestAirportFx.doneData,
          tripInfoModel.checkOutChanged,
          $$getUserPositionFactory.$userAirport,
          tripIdeaModel.$$getTripIdeaByIdFactory.getTripIdeaByIdCompleted,
        ],
        source: {
          passengers: $passengers,
          fromAirport: $fromAirport,
          toAirport: $$manageLocalStorageForSearchFlights.$toAirportCode,
          flightType: $$manageLocalStorageForSearchFlights.$flightType,
          userAirport: $$getUserPositionFactory.$userAirport,
          isDateRangeInvalid: chooseTripDatesModel.$isDateRangeInvalid,
          dates: tripInfoModel.$selectedTripDates,
          loading: $loading,
          isFlightsTestEnv: $$manageLocalStorageForSearchFlights.$isFlightsTestEnv,
          isFlightsIncluded: $$manageLocalStorageForSearchFlights.$isFlightsIncluded,
          isGetTripIdeaByIdCompleted:
            tripIdeaModel.$$getTripIdeaByIdFactory.$isGetTripIdeaByIdCompleted,
        },
        filter: ({
          fromAirport,
          toAirport,
          userAirport,
          isDateRangeInvalid,
          loading,
          isFlightsIncluded,
          isGetTripIdeaByIdCompleted,
        }) => {
          const ifCodeIsNotMatched = fromAirport?.iata_code
            ? fromAirport?.iata_code !== toAirport
            : userAirport?.iataCityCode !== toAirport

          return (
            (!!fromAirport?.iata_code ||
              !!fromAirport?.iata_city_code ||
              !!userAirport?.iataCityCode) &&
            !!toAirport &&
            ifCodeIsNotMatched &&
            !isDateRangeInvalid &&
            !loading &&
            isFlightsIncluded &&
            isGetTripIdeaByIdCompleted
          )
        },
        fn: ({
          passengers,
          fromAirport,
          toAirport,
          flightType,
          userAirport,
          dates,
          isFlightsTestEnv,
        }) => {
          const startDate = formatToHyphenDate(dates.startDate) ?? ''
          const endDate = formatToHyphenDate(dates.endDate) ?? ''
          const origin =
            fromAirport?.iata_code ||
            fromAirport?.iata_city_code ||
            userAirport?.iataCityCode ||
            ''
          const destination = toAirport

          const result = convertToDataBodyForSearchFlight({
            passengers,
            origin,
            destination,
            startDate,
            endDate,
            flightType,
            isFlightsTestEnv,
          })

          return result
        },

        target: searchFlightsFx,
      })
    })

    // success
    bridge(() => {
      sample({
        clock: searchFlightsFx.doneData,
        fn: (res) => res.data?.offers || [],
        target: [
          $$manageLocalStorageForSearchFlights.updateOffers,
          $$manageLocalStorageForSearchFlights.updateRecommendedOffers,
        ],
      })
    })

    // error
    bridge(() => {
      sample({
        clock: searchFlightsFx.failData,
        fn: (error) => error.message,
        target: $error,
      })

      sample({
        clock: searchFlightsFx.finally,
        fn: () => null,
        target: $$manageLocalStorageForSearchFlights.$selectedOffer,
      })

      reset({
        clock: searchFlightsFx,
        target: [
          $$manageLocalStorageForSearchFlights.$offers,
          $error,
          $$manageLocalStorageForSearchFlights.$selectedOffer,
          $$manageLocalStorageForSearchFlights.$options,
        ],
      })

      reset({
        clock: resetAdultsCount,
        target: $adultsCount,
      })

      reset({
        clock: resetChildrenCount,
        target: $childrenCount,
      })
    })

    persist({ store: $fromAirport, key: LocalStorageKeys.SelectedOrigin })

    return {
      $loading,
      $error,
      $fromAirport,
      $startDate,
      $endDate,
      $adultsCount,
      $childrenCount,
      searchFlightsFx,

      adultsCountChanged,
      updateError,
      resetAdultsCount,
      resetChildrenCount,
      fromAirportChanged,
      childrenCountChanged,
    }
  },
)
