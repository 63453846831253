import {
  GenerateItineraryDto,
  RegenerateItineraryDto,
} from '@softcery/awayaway-nodejs-api-client'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { tripIdeaModel } from '~/entities/destination/model'
import { apiServiceNew, errorHandler, getErrorMessage } from '~/shared/api'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { LocalStorageKeys } from '~/shared/config/localstorage'
import { formatToDate } from '~/shared/lib/date'
import { bridge } from '~/shared/lib/factory'
import { Itinerary } from './types'
// todo todo
export const stateReset = createEvent()
export const resetItineraryFromAllItineraries = createEvent<string>()

export const getTripItinerariesFx = createEffect(
  async ({
    // destination code using for caching itinerary
    destinationCode,
    fields,
  }: {
    destinationCode: string
    fields: GenerateItineraryDto
  }) => {
    try {
      const res = await apiServiceNew.appClient.itinerary.generateItinerary(fields)
      return res
    } catch (res: any) {
      return errorHandler(res, 'Failed to get trip itinerary')
    }
  },
)

export const regenerateTripItinerariesFx = createEffect(
  async ({
    // destination code using for caching itinerary
    destinationCode,
    fields,
  }: {
    destinationCode: string
    fields: RegenerateItineraryDto
  }) => {
    try {
      const res = await apiServiceNew.appClient.itinerary.regenerateItinerary(fields)
      return res
    } catch (res: any) {
      return errorHandler(res, 'Failed to regenerate trip itinerary')
    }
  },
)

export const updateAllItineraries = createEvent<{ [key: string]: Itinerary[] }>()
// itinerary for every trip saved by destination code
export const $allItineraries = restore<{ [key: string]: Itinerary[] }>(
  updateAllItineraries,
  {},
)

export const $itineraryRegenerateStarted = createStore(false)

// itinerary for current trip
export const $itineraries = combine(
  $allItineraries,
  tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
  (allItineraries, trip) => allItineraries[trip?.destinationCode || ''] || [],
)

export const $pendingItinerariesTripsCodes = createStore<string[]>([])

export const regenerateTripItinerariesWhenDateChanged = createEvent()

bridge(() => {
  sample({
    clock: resetItineraryFromAllItineraries,
    source: $allItineraries,
    fn: (allItineraries, destinationCode) => {
      const copyOfItineraries = { ...allItineraries }
      delete copyOfItineraries[destinationCode]
      return copyOfItineraries
    },
    target: updateAllItineraries,
  })
})

bridge(() => {
  sample({
    clock: [getTripItinerariesFx, regenerateTripItinerariesFx],
    source: $pendingItinerariesTripsCodes,
    fn: (codes, { destinationCode }) => [...codes, destinationCode],
    target: $pendingItinerariesTripsCodes,
  })

  sample({
    clock: [getTripItinerariesFx.done, regenerateTripItinerariesFx.done],
    source: $pendingItinerariesTripsCodes,
    fn: (codes, { params: { destinationCode } }) =>
      codes.filter((code) => code !== destinationCode),
    target: $pendingItinerariesTripsCodes,
  })
})

sample({
  clock: [getTripItinerariesFx.done, regenerateTripItinerariesFx.done],
  source: $allItineraries,
  fn: (allItineraries, { params: { destinationCode }, result }) => {
    const dayFormat = 'YYYY-MM-DD'

    // sort itineraries by day
    const sortedItineraries =
      (result?.length || 0) >= 2
        ? result?.sort(
            (a, b) =>
              formatToDate(a.day!, dayFormat).getTime() -
              formatToDate(b.day!, dayFormat).getTime(),
          )
        : result

    return { ...allItineraries, [destinationCode]: sortedItineraries || [] }
  },
  target: updateAllItineraries,
})

sample({
  clock: regenerateTripItinerariesFx,
  fn: () => true,
  target: $itineraryRegenerateStarted,
})

reset({
  clock: regenerateTripItinerariesFx.finally,
  target: $itineraryRegenerateStarted,
})

export const $getItinerariesStatus = combine({
  loading: combine(
    $pendingItinerariesTripsCodes,
    tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
    (codes, trip) => codes.includes(trip?.destinationCode || ''),
  ),
  error: combine(
    restore(getTripItinerariesFx.finally, null)
      .map(getErrorMessage)
      .reset(getTripItinerariesFx),
    restore(regenerateTripItinerariesFx.finally, null)
      .map(getErrorMessage)
      .reset(regenerateTripItinerariesFx),
    (a, b) => a || b,
  ),
})

persist({ store: $allItineraries, key: LocalStorageKeys.TripsItineraries })

reset({
  clock: stateReset,
  target: [$allItineraries],
})

export const openTripDetailsFromEmail = createEvent()

sample({
  clock: openTripDetailsFromEmail,
  fn: () => ({
    name: eventNames.bookingConfirmationVisited,
  }),
  target: analyticsModel.track,
})
