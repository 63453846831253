import { createEffect, createEvent, sample } from 'effector'
import { spread } from 'patronum'

import { viewerModel } from '~/entities/viewer'
import { apiService, errorHandler, updateApiServiceToken } from '~/shared/api'
import { AppRoute } from '~/shared/config'
import { atom } from '~/shared/lib/factory'
import { navigationModel } from '~/shared/models/navigation'
import { marketingEmailsAllowInitiated } from '../../update-personal-info/model'

type VerificationToken = string
type AccessToken = string

export const verifyUserTokenModel = atom(() => {
  const verificationTokenReceived = createEvent<VerificationToken>()

  const userVerified = createEvent()
  const userVerificationFailed = createEvent<Error>()

  const updateAccessTokenFx = createEffect((accessToken: AccessToken) => {
    updateApiServiceToken(accessToken)
  })

  const verifyTokenFx = createEffect(async (verificationToken: VerificationToken) => {
    try {
      return await apiService().verifyUser({ verificationToken })
    } catch (res: any) {
      return errorHandler(res, 'Failed to verify token')
    }
  })

  const getUserInfoFx = createEffect(async () => {
    try {
      return await apiService().getUser()
    } catch (res: any) {
      return errorHandler(res, 'Failed to verify token')
    }
  })

  sample({
    clock: navigationModel.$path,
    filter: (path) => path.startsWith(AppRoute.UserVerify),
    fn: (path) => {
      const verifyToken = path.split('/').at(-1)
      return verifyToken!
    },
    target: verificationTokenReceived,
  })

  sample({
    source: verificationTokenReceived,
    target: verifyTokenFx,
  })

  sample({
    clock: verifyTokenFx.doneData,
    fn: ({ accessToken }) => accessToken!,
    target: updateAccessTokenFx,
  })

  spread({
    source: verifyTokenFx.doneData,
    targets: {
      accessToken: viewerModel.accessTokenUpdated,
      refreshToken: viewerModel.refreshTokenUpdated,
    },
  })

  sample({
    clock: verifyTokenFx.doneData,
    target: getUserInfoFx,
  })

  sample({
    clock: verifyTokenFx.doneData,
    target: userVerified,
  })

  sample({
    clock: verifyTokenFx.doneData,
    target: marketingEmailsAllowInitiated,
  })

  // TODO: Add additional logic to handle error
  sample({
    clock: verifyTokenFx.fail,
    fn: () => {
      return AppRoute.Destination
    },
    target: navigationModel.pathReplace,
  })

  sample({
    clock: verifyTokenFx.failData,
    target: userVerificationFailed,
  })

  sample({
    clock: getUserInfoFx.doneData,
    fn: ({ user }) => user!,
    target: viewerModel.updated,
  })

  sample({
    clock: getUserInfoFx.doneData,
    fn: () => AppRoute.Home,
    target: navigationModel.pathReplace,
  })

  return {
    userVerified,
    userVerificationFailed,
    getUserInfoFx,
    verifyTokenFx,
  }
})
