import { UnsubscribeEmailEngagementDto } from '@softcery/awayaway-nodejs-api-client'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { atom } from '~/shared/lib/factory'
import { unsubscribeEmail } from './api'

export const unsubscribeModel = atom(() => {
  const Gate = createGate()

  const initiated = createEvent<UnsubscribeEmailEngagementDto>()
  const unsubscribeEmailFx = createEffect(unsubscribeEmail)

  const $isPending = unsubscribeEmailFx.pending
  const $message = createStore('Loading...')

  sample({
    clock: initiated,
    fn: (fields) => {
      return fields
    },
    target: unsubscribeEmailFx,
  })

  sample({
    clock: unsubscribeEmailFx.doneData,
    fn: () =>
      'You have been successfully removed from subscriber list and won`t receiveany further emails from us.',
    target: $message,
  })

  sample({
    clock: unsubscribeEmailFx.failData,
    fn: () =>
      'It looks like you’ve already unsubscribed, or something went wrong with your request. Please try again later.',
    target: $message,
  })

  sample({
    clock: unsubscribeEmailFx.done,
    fn: ({ params }) => ({
      name: eventNames.unsubscribeEmailCompleted,
      properties: {
        email: params.email,
      },
    }),
    target: analyticsModel.track,
  })

  reset({
    clock: Gate.close,
    target: $message,
  })

  return {
    initiated,
    $isPending,
    $message,
    Gate,
  }
})
