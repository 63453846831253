import { css } from '@emotion/react'
import { Colors, Fonts } from '~/shared/styles'

export const styles = {
  container: css`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,

  title: css`
    font-size: 42px;
    font-family: ${Fonts.Joane};
    color: ${Colors.NEUTRAL_BLACK};
  `,

  desc: css`
    margin-top: 12px;
    font-size: 20px;
    max-width: 450px;
    text-align: center;
    color: ${Colors.NEUTRAL_DARK};
    min-height: 46px;
  `,

  button: css`
    margin-top: 24px;
  `,
}
