import { createFactory } from '@withease/factories'
import { createEffect, createEvent, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import {
  GroupWithRoomsResponse,
  HotelWithRoomsResponse,
  SearchHotels,
  SearchHotelsResponse,
} from '~/entities/room'
import { sortRoomsBySelected } from '~/entities/room/libs'
import { getRooms } from '~/entities/room/model/get-rooms/api'
import { LocalStorageKeys } from '~/shared/config'
import { bridge } from '~/shared/lib/factory'

export type GetRoomsFactory = ReturnType<typeof getRoomsFactory>
export const getRoomsFactory = createFactory(() => {
  const getRoomsFx = createEffect<SearchHotels, SearchHotelsResponse, Error>(getRooms)

  const stateReset = createEvent()

  const updateGroupWithRoomsListChanged = createEvent<GroupWithRoomsResponse[] | null>()
  const $groupWithRoomsList = restore<GroupWithRoomsResponse[] | null>(
    updateGroupWithRoomsListChanged,
    null,
  )

  const updateHotelWithRooms = createEvent<HotelWithRoomsResponse[] | null>()
  const $hotelWithRooms = restore<HotelWithRoomsResponse[] | null>(
    updateHotelWithRooms,
    null,
  )

  const updateSelectedHotelCode = createEvent<number | null>()
  const $selectedHotelCode = restore(updateSelectedHotelCode, null)

  const updateSelectedHotel = createEvent<HotelWithRoomsResponse | null>()
  const $selectedHotel = restore(updateSelectedHotel, null)

  const $loading = getRoomsFx.pending
  // handle success and fill data
  bridge(() => {
    sample({
      clock: getRoomsFx.doneData,
      fn: (res) => {
        const copySelectedHotel = res?.data?.hotels
          ? JSON.parse(JSON.stringify(res.data.hotels))
          : null
        return copySelectedHotel ? sortRoomsBySelected(copySelectedHotel) : null
      },
      target: updateHotelWithRooms,
    })

    // Get only selected hotel
    sample({
      clock: [updateHotelWithRooms, updateSelectedHotelCode],
      source: {
        selectedCodeHotel: $selectedHotelCode,
        hotelsWithRooms: $hotelWithRooms,
      },
      filter: ({ selectedCodeHotel, hotelsWithRooms }) => {
        return !!hotelsWithRooms?.find((hotel) => hotel.code === selectedCodeHotel)
      },
      fn: ({ selectedCodeHotel, hotelsWithRooms }) => {
        return hotelsWithRooms?.find((hotel) => hotel.code === selectedCodeHotel) || null
      },
      target: updateSelectedHotel,
    })

    // get rooms with group data
    sample({
      clock: updateSelectedHotel,
      fn: (selectedHotel) => selectedHotel?.group || null,
      target: updateGroupWithRoomsListChanged,
    })
  })

  // handle reset
  bridge(() => {
    reset({
      clock: [stateReset, getRoomsFx.fail],
      target: [$groupWithRoomsList, $hotelWithRooms, $selectedHotelCode, $selectedHotel],
    })
  })

  persist({ store: $groupWithRoomsList, key: LocalStorageKeys.GroupWithRoomsList })

  return {
    $groupWithRoomsList,
    $loading,
    $hotelWithRooms,
    $selectedHotelCode,
    $selectedHotel,

    updateSelectedHotel,
    stateReset,
    updateHotelWithRooms,
    updateSelectedHotelCode,
    updateGroupWithRoomsListChanged,
    getRoomsFx,
  }
})
