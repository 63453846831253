import { invoke } from '@withease/factories'
import { combine, createEvent, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { tripsAnalyticsFactory } from '~/entities/trip/model/analytics'
import { manageNavigationForTripsFactory } from '~/entities/trip/model/manage-navigation-for-trips'
import { manageTripsDataFactory } from '~/entities/trip/model/manage-trips-data/manage-trips-data'
import { getErrorMessage } from '~/shared/api'
import { LocalStorageKeys } from '~/shared/config'
import { atom } from '~/shared/lib/factory'
import { getRecommenderTripsFactory } from './get-recommender-trips'
import { getSavedTripsFactory } from './get-saved-trips'
import { getTripsBySourceIdFactory } from './get-trips-by-source-id'
import { getUpdatedTrips } from './get-updated-trips'
import { saveUserTripsFactory } from './save-user-trips'

export const tripsModel = atom(() => {
  const resetState = createEvent()
  const resetErrors = createEvent()
  const $$manageTripsDataFactory = invoke(manageTripsDataFactory)

  const $$getRecommenderTripsFactory = invoke(getRecommenderTripsFactory, {
    $$manageTripsDataFactory,
  })

  const $$getTripsBySourceIdFactory = invoke(getTripsBySourceIdFactory, {
    $$manageTripsDataFactory,
  })

  const $$getSavedTripsFactory = invoke(getSavedTripsFactory, {
    $$manageTripsDataFactory,
  })
  const $$getUpdatedTripsFactory = invoke(getUpdatedTrips, { $$manageTripsDataFactory })

  const $$saveUserTripsFactory = invoke(saveUserTripsFactory)

  const $$manageNavigationForTripsFactory = invoke(manageNavigationForTripsFactory, {
    $$manageTripsDataFactory,
  })

  invoke(tripsAnalyticsFactory, {
    $$getRecommenderTripsFactory,
  })

  const getTripsStatusReset = createEvent()

  const $getTripsStatus = combine({
    loading: combine(
      $$getRecommenderTripsFactory.getTripsFx.pending,
      $$getSavedTripsFactory.getUserSavedTripsFx.pending,
      $$getTripsBySourceIdFactory.getTripsBySourceIdFx.pending,
      $$getUpdatedTripsFactory.getUpdatedTripsFx.pending,
      (a, b, c, d) => a || b || c || d,
    ),
    error: combine(
      restore($$getRecommenderTripsFactory.getTripsFx.finally, null)
        .map(getErrorMessage)
        .reset(resetErrors),
      restore($$getSavedTripsFactory.getUserSavedTripsFx.finally, null)
        .map(getErrorMessage)
        .reset(resetErrors),
      restore($$getTripsBySourceIdFactory.getTripsBySourceIdFx.finally, null)
        .map(getErrorMessage)
        .reset(resetErrors, $$getTripsBySourceIdFactory.resetError),
      restore($$getUpdatedTripsFactory.getUpdatedTripsFx.finally, null)
        .map(getErrorMessage)
        .reset(resetErrors, $$getTripsBySourceIdFactory.resetError),
      (a, b, c, d) => a || b || c || d,
    ),
  })

  sample({
    clock: [
      $$getRecommenderTripsFactory.getTripsFx.doneData,
      $$getSavedTripsFactory.getUserSavedTripsFx.doneData,
      $$getTripsBySourceIdFactory.getTripsBySourceIdFx.doneData,
      $$getUpdatedTripsFactory.getUpdatedTripsFx.doneData,
    ],
    target: resetErrors,
  })

  sample({
    clock: $$getTripsBySourceIdFactory.$error,
    filter: (error) => !!error,
    target: [
      $$getSavedTripsFactory.getUserSavedTripsFx,
      $$getTripsBySourceIdFactory.resetError,
    ],
  })

  sample({
    clock: resetState,
    target: [
      $$getRecommenderTripsFactory.stateReset,
      $$manageTripsDataFactory.resetState,
      $$manageNavigationForTripsFactory.resetState,
      $$getTripsBySourceIdFactory.resetError,
    ],
  })

  reset({
    clock: getTripsStatusReset,
    target: $getTripsStatus,
  })

  persist({ store: $getTripsStatus, key: LocalStorageKeys.TripsStatus })

  return {
    $getTripsStatus,

    getTripsStatusReset,
    resetState,

    $$manageNavigationForTripsFactory,
    $$getTripsBySourceIdFactory,
    $$getRecommenderTripsFactory,
    $$getSavedTripsFactory,
    $$saveUserTripsFactory,
    $$manageTripsDataFactory,
    $$getUpdatedTripsFactory,
  }
})
