import { Global, SerializedStyles } from '@emotion/react'
import { useStore } from 'effector-react'
import { FC, Fragment, useLayoutEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { summaryPageModel } from '~/pages/onboarding/summary'
import { tripsModel } from '~/entities/trip/model'
import { useScrollLock } from '~/shared/lib/use-scroll-lock'
import { globalStyles, styles } from './styles'

interface Props {
  isOpen: boolean
  extendStyles?: {
    modal?: SerializedStyles
    wrapper?: SerializedStyles
  }
  centred?: boolean
  onClose?: () => void
  isActionRequired?: boolean
}

export const Modal: FC<Props> = ({
  isOpen,
  extendStyles,
  children,
  onClose,
  centred,
  isActionRequired,
}) => {
  const nodeRef = useRef(null)

  const [lockScroll, unlockScroll] = useScrollLock()
  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const isTripsLoading = useStore(tripsModel.$$getRecommenderTripsFactory.$isTripsLoading)

  const isLoaderVisible = isTripsLoading || isNewSummaryPending

  useLayoutEffect(() => {
    if (isOpen) {
      lockScroll()
    }

    return () => {
      unlockScroll()
    }
  }, [isOpen])

  const onCloseHandler = () => {
    if (isActionRequired === true) {
      return null
    } else {
      onClose?.()
    }
  }

  return (
    <Fragment>
      <Global styles={globalStyles} />
      <CSSTransition
        in={isOpen}
        timeout={500}
        nodeRef={nodeRef}
        classNames="modal"
        unmountOnExit
      >
        <section
          ref={nodeRef}
          onClick={onCloseHandler}
          css={[styles.modal(!!centred, isLoaderVisible), extendStyles?.modal]}
          data-testid="modal"
        >
          <div
            css={[styles.wrapper, extendStyles?.wrapper]}
            onClick={(evt) => evt.stopPropagation()}
          >
            <div data-testid="modal-content">{children}</div>
          </div>
        </section>
      </CSSTransition>
    </Fragment>
  )
}
