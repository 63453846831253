import { createFactory } from '@withease/factories'
import { createEvent } from 'effector'
import { restore } from 'effector/compat'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { TripIdeaRes } from '~/entities/destination/types'
import { LocalStorageKeys } from '~/shared/config'

export type ManageTripIdeaFactory = ReturnType<typeof manageTripIdeaFactory>
export const manageTripIdeaFactory = createFactory(() => {
  const resetState = createEvent()

  const selectedDestination = createEvent<TripIdeaRes | null>()
  const $selectedDestination = restore(selectedDestination, null)

  reset({
    clock: resetState,
    target: [$selectedDestination],
  })

  persist({ store: $selectedDestination, key: LocalStorageKeys.SelectedDestination })

  return {
    $selectedDestination,

    selectedDestination,
    resetState,
  }
})
