import { apiServiceNew, errorHandler } from '~/shared/api'
import { SaveRecommendedTripsForRequest, SavedRecommendedTrips } from '../../types'

export const saveUserTrips = async (
  fields: SaveRecommendedTripsForRequest,
): Promise<SavedRecommendedTrips | undefined> => {
  try {
    const res = await apiServiceNew.appClient.savedRecommendations.saveTrips(fields)

    return res.data
  } catch (res: any) {
    return errorHandler(res, 'Failed to save user trips')
  }
}
