import { MutatedTripIdea, TripIdeaHotelRes } from '~/entities/hotel/types'

export const selectHotelAndAddIsSelectedField = (
  hotels: TripIdeaHotelRes[] | MutatedTripIdea[],
  selectedHotel: TripIdeaHotelRes | null | MutatedTripIdea,
): {
  hotelsWithSelectedOne: MutatedTripIdea[]
  selectedOne: MutatedTripIdea
} => {
  if (!selectedHotel) {
    const hotelsWithSelectedOne = hotels.map((hotel, index) => ({
      ...hotel,
      isSelected: index === 0,
    })) as MutatedTripIdea[]
    const selectedOne = hotelsWithSelectedOne.find(({ isSelected }) => isSelected)!
    return { hotelsWithSelectedOne, selectedOne }
  }
  const hotelsWithSelectedOne = hotels.map((hotel) => ({
    ...hotel,
    isSelected: hotel.code === selectedHotel.code,
  }))

  const selectedOne = hotelsWithSelectedOne.find(({ isSelected }) => isSelected)!

  return { hotelsWithSelectedOne, selectedOne }
}
