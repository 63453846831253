import { createEffect, createEvent, createStore, sample } from 'effector'
import { reset } from 'patronum'
import { tripIdeaModel } from '~/entities/destination/model'
import { apiService, errorHandler } from '~/shared/api'

export const tripDislikeProcessInitiated = createEvent()
export const tripDisliked = createEvent()
export const tripLiked = createEvent()
export const feedbackTextChanged = createEvent<string>()
export const closeFeedbackModal = createEvent()
export const tripRated = createEvent<{ id: number; description: string; rate: number }>()

const likeTripFx = createEffect(async (fields: any) => {
  try {
    const res = await apiService().giveTripRecommendationFeedback({ fields })
    return res
  } catch (res: any) {
    return errorHandler(res, 'Failed to like trip')
  }
})

const dislikeTripFx = createEffect(async (fields: any) => {
  try {
    const res = await apiService().giveTripRecommendationFeedback({ fields })
    return res
  } catch (res: any) {
    return errorHandler(res, 'Failed to dislike trip')
  }
})

export const $feedbackModalOpened = createStore(false)
const $feedbackText = createStore('')
export const $isFeedbackFormSubmmited = createStore(false)
export const $selectedTripFeedback = createStore<number | null>(null)

sample({
  clock: tripDislikeProcessInitiated,
  fn: () => true,
  target: $feedbackModalOpened,
})

sample({
  clock: tripLiked,
  source: {
    trip: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
    description: $feedbackText,
  },
  fn: ({ trip, description }) => ({
    rate: 1,
    id: trip!.id,
    description: description,
  }),
  target: [likeTripFx, tripRated],
})

sample({
  clock: tripDisliked,
  source: {
    trip: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
    description: $feedbackText,
  },
  fn: ({ trip, description }) => ({
    rate: 0,
    id: trip!.id,
    description: description,
  }),
  target: [dislikeTripFx, tripRated],
})

sample({
  clock: closeFeedbackModal,
  fn: () => false,
  target: $feedbackModalOpened,
})

sample({
  clock: dislikeTripFx.doneData,
  fn: () => true,
  target: $isFeedbackFormSubmmited,
})

sample({
  clock: likeTripFx.doneData,
  fn: () => 1,
  target: $selectedTripFeedback,
})

sample({
  clock: dislikeTripFx.doneData,
  fn: () => 0,
  target: $selectedTripFeedback,
})

sample({
  clock: feedbackTextChanged,
  target: $feedbackText,
})

reset({
  clock: closeFeedbackModal,
  target: [$feedbackText, $isFeedbackFormSubmmited],
})
