import { createFactory } from '@withease/factories'
import { createEvent, restore, sample, createEffect } from 'effector'
import { reset } from 'patronum'
import { SaveRecommendedTripsForRequest } from '~/entities/trip'
import { bridge } from '~/shared/lib/factory'
import { saveUserTrips } from './api'

export const saveUserTripsFactory = createFactory(() => {
  const resetState = createEvent()
  const initiated = createEvent<SaveRecommendedTripsForRequest>()
  const saveUserTripsFx = createEffect(saveUserTrips)

  const $isPending = saveUserTripsFx.pending
  const updateError = createEvent()
  const $error = restore(updateError, null)

  bridge(() => {
    sample({
      clock: initiated,
      target: saveUserTripsFx,
    })
  })

  // error handling
  bridge(() => {
    sample({
      source: saveUserTripsFx.failData,
      target: updateError,
    })
  })

  reset({
    clock: resetState,
    target: [$error],
  })

  return {
    $isPending,
    $error,

    initiated,

    saveUserTripsFx,
  }
})
