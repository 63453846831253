import { createEffect, createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { LocalStorageKeys } from '~/shared/config'
import { navigationModel } from '~/shared/models/navigation'

export const logoClicked = createEvent()
export const logoClickedTracked = createEvent()
export const stepNavigated = createEvent<number>()
export const stepCompleted = createEvent()
export const startNavigated = createEvent()
export const previousStepNavigated = createEvent()
export const stateReset = createEvent()

const openLandingPageFx = createEffect((url: string) => {
  window.location.replace(url)
})

export const $lastCompletedStep = createStore(1)
persist({ store: $lastCompletedStep, key: LocalStorageKeys.lastCompletedStep })

sample({
  clock: logoClicked,
  source: navigationModel.$landingPageUrl,
  target: openLandingPageFx,
})

sample({
  clock: logoClickedTracked,
  fn: () => ({
    name: eventNames.logoClicked,
  }),

  target: analyticsModel.track,
})

sample({
  clock: stepCompleted,
  source: {
    currentStep: onboardingSessionModel.$step,
    lastCompletedStep: $lastCompletedStep,
  },
  filter: ({ currentStep, lastCompletedStep }) => !(currentStep < lastCompletedStep),
  fn: ({ currentStep }) => {
    return currentStep + 1
  },
  target: $lastCompletedStep,
})

reset({
  clock: stateReset,
  target: $lastCompletedStep,
})
