import { apiErrorHandler, apiServiceNew } from '~/shared/api'
import { RecommendTripsRes, TripSourceId } from '../../types'

export const getTripsBySourceId = async (
  resourceId: TripSourceId,
): Promise<RecommendTripsRes> => {
  try {
    return await apiServiceNew.appClient.tripsRecommendations.getTripByTripsResourceId(
      resourceId,
    )
  } catch (e) {
    return apiErrorHandler(e)
  }
}
