import { createEvent, sample, createEffect, createStore } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset, equals } from 'patronum'
import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { scheduleTripDatesModel } from '~/features/onboarding/schedule-trip-dates'
import { selectImagesModel } from '~/features/onboarding/select-images'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { $animationData, animationDataRequested } from '~/entities/loader/model'
import {
  LoadingMessagesBodyDto,
  LoadingMessagesResponse,
  ModifyBodyDto,
  ModifyResponse,
  onboardingSessionModel,
  SummaryBodyDto,
  SummaryResponse,
} from '~/entities/onboarding-session'
import { newRecommendationsInitiated } from '~/entities/onboarding-session/model'
import { apiServiceNew } from '~/shared/api'
import { LocalStorageKeys } from '~/shared/config'

export const Gate = createGate<void>()

export const nextStepNavigated = createEvent()

export const summaryPageOpened = createEvent()
export const summaryEdited = createEvent()
export const summaryChanged = createEvent<string>()
export const summaryUpdated = createEvent()
export const summaryEditCanceled = createEvent()
export const stateReset = createEvent()

export const loadingMessagesRequested = createEvent()

export const getLoadingMessagesFx = createEffect(
  async ({
    categories,
    numberOfMessages,
  }: LoadingMessagesBodyDto): Promise<LoadingMessagesResponse> => {
    const response = await apiServiceNew.appClient.categories.generateLoadingMessage({
      categories,
      numberOfMessages,
    })
    return response
  },
)

const getSummaryFx = createEffect(
  async ({ categories }: SummaryBodyDto): Promise<SummaryResponse> => {
    const response = await apiServiceNew.appClient.categories.getSummarizedCategories({
      categories,
    })

    return response
  },
)

const modifySummaryFx = createEffect(
  async ({ categories, update, summary }: ModifyBodyDto): Promise<ModifyResponse> => {
    const response = await apiServiceNew.appClient.categories.modifyCategories({
      categories,
      update,
      summary,
    })

    return response
  },
)

export const $currentSummary = createStore('')

export const $initialSummary = createStore<string>('')
persist({ store: $initialSummary, key: LocalStorageKeys.Summary })

export const $customSummary = createStore<string>('')
persist({ store: $customSummary, key: LocalStorageKeys.CustomSummary })

export const $isUpdated = createStore(false)
persist({ store: $isUpdated, key: LocalStorageKeys.IsSummaryUpdated })

const $numberOfMessages = createStore<number>(8)
export const $loadingMessages = createStore<string[]>([
  "We're preparing your personalised recommendations",
  'We check availability for your dates in advance',
  'Recommendations are curated by leading travel journalists',
  'We cover global destinations, but we might be missing your exact requested location',
])

export const $isEditing = createStore(false)
export const $isSummaryPending = getSummaryFx.pending
export const $isLoadingMessagesPending = getLoadingMessagesFx.pending
export const $isNewSummaryPending = modifySummaryFx.pending
export const $isEquals = equals($currentSummary, $initialSummary)

sample({
  clock: Gate.open,
  target: onboardingSessionModel.scrollToTopFx,
})

// get loader
sample({
  clock: Gate.open,
  source: $animationData,
  filter: (animationData) => !animationData,
  target: animationDataRequested,
})
//Get initial summary
sample({
  clock: listImageRecommendationsModel.imagesLabelsSelected,
  source: {
    categories: listImageRecommendationsModel.$imagesLabels,
    selectImages: selectImagesModel.$selectedImageIds,
  },
  filter: ({ selectImages, categories }) =>
    Boolean(selectImages.length) && Boolean(categories.length),
  fn: ({ categories }) => ({ categories }),
  target: getSummaryFx,
})

sample({
  clock: getSummaryFx.doneData,
  fn: ({ summary }) => summary,
  target: $initialSummary,
})

//Update current summary logic
sample({
  clock: [$initialSummary, summaryEditCanceled, Gate.open],
  source: {
    summary: $initialSummary,
    customSummary: $customSummary,
    isUpdated: $isUpdated,
  },
  fn: ({ summary, customSummary, isUpdated }) => (!isUpdated ? summary : customSummary),
  target: $currentSummary,
})

//Summary editing logic
sample({
  clock: summaryEdited,
  fn: () => true,
  target: $isEditing,
})

sample({
  clock: summaryChanged,
  fn: (newSummary) => newSummary,
  target: $currentSummary,
})

sample({
  clock: summaryUpdated,
  source: { newSummary: $currentSummary, isEquals: $isEquals },
  filter: ({ isEquals }) => !isEquals,
  fn: ({ newSummary }) => newSummary,
  target: $customSummary,
})

sample({
  clock: summaryUpdated,
  source: $isEquals,
  filter: ($isEquals) => !$isEquals,
  fn: () => true,
  target: $isUpdated,
})

sample({
  clock: summaryUpdated,
  source: {
    categories: listImageRecommendationsModel.$imagesLabels,
    update: $customSummary,
    summary: $initialSummary,
    isEquals: $isEquals,
  },
  filter: ({ isEquals }) => !isEquals,
  fn: ({ categories, update, summary }) => ({ categories, update, summary }),
  target: modifySummaryFx,
})

sample({
  clock: summaryUpdated,
  source: {
    categories: listImageRecommendationsModel.$imagesLabels,
    update: $customSummary,
    summary: $initialSummary,
    isEquals: $isEquals,
  },
  filter: ({ isEquals }) => isEquals,
  target: nextStepNavigated,
})

sample({
  clock: modifySummaryFx.doneData,
  fn: ({ categories }) => categories,
  target: listImageRecommendationsModel.$imagesLabels,
})

sample({
  clock: getLoadingMessagesFx.doneData,
  fn: ({ messages }) => messages,
  target: $loadingMessages,
})

sample({
  clock: loadingMessagesRequested,
  source: {
    categories: listImageRecommendationsModel.$imagesLabels,
    numberOfMessages: $numberOfMessages,
  },
  fn: ({ categories, numberOfMessages }) => ({ categories, numberOfMessages }),
  target: getLoadingMessagesFx,
})

sample({
  clock: modifySummaryFx.doneData,
  target: newRecommendationsInitiated,
})

// Reset
sample({
  clock: [Gate.open, newRecommendationsInitiated],
  filter: scheduleTripDatesModel.$isFlexibleOptionSelected,
  target: onboardingSessionModel.exactDatesRemoved,
})

reset({
  clock: summaryUpdated,
  target: $isEditing,
})

reset({
  clock: summaryEditCanceled,
  target: [$isEditing, $currentSummary],
})

reset({
  clock: selectImagesModel.$selectedImageIds,
  target: [$customSummary, $isUpdated],
})

reset({
  clock: stateReset,
  target: [$initialSummary, $isUpdated, $customSummary],
})

sample({
  clock: stateReset,
  fn: () => 5,
  target: onboardingLayoutModel.$lastCompletedStep,
})

reset({
  clock: onboardingSessionModel.statusReset,
  target: $loadingMessages,
})
