import { RegisterUserRequestBody } from '@softcery/awayaway-apiclient'
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { createForm } from 'effector-forms'
import { createGate } from 'effector-react'
import { reset, spread } from 'patronum'

import { onboardingSessionModel } from '~/entities/onboarding-session'
import { popupModel } from '~/entities/popup'
import { viewerModel } from '~/entities/viewer'
import {
  apiService,
  errorHandler,
  getErrorMessage,
  updateApiServiceToken,
} from '~/shared/api'
import { ManageUrlQueryKeys } from '~/shared/config'
import { countdownFactory } from '~/shared/lib/countdown'
import { atom, bridge } from '~/shared/lib/factory'

import { notification } from '~/shared/lib/notification'
import { rules } from '~/shared/lib/validation'
import { getLoggedInUserFx } from '../../login-profile/model'
import { trimStringsInObject } from '../../update-personal-info/lib'
import { RESENT_EMAIL_COUNTDOWN } from '../config'

export const saveProfileModel = atom(() => {
  const Gate = createGate()

  const initiated = createEvent()
  const resentInitiated = createEvent()
  const saveProfileInitiated = createEvent()

  const saveProfileFx = createEffect(async (fields: RegisterUserRequestBody) => {
    try {
      const response = await apiService().registerUser({ fields })
      updateApiServiceToken(response.accessToken!)
      return response
    } catch (res: any) {
      return errorHandler(res, 'Failed to send email')
    }
  })

  const resentConfirmationEmailFx = createEffect(async (email: string) => {
    try {
      return await apiService().sendVerificationLink({
        email,
      })
    } catch (res: any) {
      return errorHandler(res, 'Failed to resend email')
    }
  })

  const $$form = createForm({
    fields: {
      fullName: {
        init: '',
        rules: [rules.minLength(1), rules.maxLength(100)],
      },
      email: {
        init: '',
        rules: [rules.leadEmail()],
      },
    },
    validateOn: ['submit'],
  })

  const $$countdownModel = countdownFactory()
  const $isConfirmationEmailSent = createStore(false)
  const $inSaveProfileInProgress = saveProfileFx.pending
  const $isResentEmailInProgress = resentConfirmationEmailFx.pending
  const $lastSentEmail = createStore('')

  const $saveProfileError = restore(saveProfileFx.finally, null).map(getErrorMessage)
  const $resentEmailError = restore(resentConfirmationEmailFx.finally, null).map(
    getErrorMessage,
  )

  const $error = combine(
    $saveProfileError,
    $resentEmailError,
    (saveProfileError, resentEmailError) => saveProfileError || resentEmailError,
  )
  const $isError = $error.map(Boolean)

  // Handle saveProfileFx
  bridge(() => {
    sample({
      clock: initiated,
      target: $$form.validate,
    })

    sample({
      clock: $$form.formValidated,
      target: saveProfileInitiated,
    })

    sample({
      clock: saveProfileInitiated,
      source: $$form.$values.map(trimStringsInObject),
      fn: (formFields) => ({
        fullName: formFields.fullName,
        email: formFields.email,
      }),
      target: saveProfileFx,
    })

    spread({
      source: saveProfileFx.doneData,
      targets: {
        accessToken: viewerModel.accessTokenUpdated,
        refreshToken: viewerModel.refreshTokenUpdated,
      },
    })

    sample({
      clock: saveProfileFx.done,
      fn: () => true,
      target: $isConfirmationEmailSent,
    })

    sample({
      clock: saveProfileFx.doneData,
      target: getLoggedInUserFx,
    })

    sample({
      clock: saveProfileFx.done,
      fn: ({ params }) => params.email,
      target: $lastSentEmail,
    })

    sample({
      clock: saveProfileFx.done,
      target: popupModel.popupHidden,
    })

    notification({
      clock: saveProfileFx.done,
      title: 'Thank you for saving your profile! ',
      message:
        "We've just sent a confirmation link to your email. Please click on it to verify your email address.",
      type: 'success',
    })
  })

  // Handle resentConfirmationEmail
  bridge(() => {
    sample({
      clock: resentInitiated,
      source: $lastSentEmail,
      target: resentConfirmationEmailFx,
    })
  })

  sample({
    clock: [saveProfileFx.done, resentConfirmationEmailFx.done],
    fn: () => RESENT_EMAIL_COUNTDOWN,
    target: $$countdownModel.countdownStarted,
  })

  reset({
    clock: [Gate.close, $$form.$values],
    target: [
      $saveProfileError,
      $resentEmailError,
      $isConfirmationEmailSent,
      $lastSentEmail,
    ],
  })

  sample({
    clock: $$form.$values,
    target: $$countdownModel.countdownAborted,
  })

  sample({
    clock: Gate.close,
    target: [$$form.reset, $$countdownModel.countdownAborted],
  })

  reset({
    clock: saveProfileFx,
    target: $saveProfileError,
  })

  reset({
    clock: resentConfirmationEmailFx,
    target: $resentEmailError,
  })

  // redirect to onboarding if it is with bookingConfirmation
  sample({
    clock: saveProfileFx.doneData,
    source: onboardingSessionModel.$bookingConfirmation,
    filter: (bookingConfirmation) => !!bookingConfirmation,
    fn: () => {
      localStorage.removeItem(ManageUrlQueryKeys.BOOKING_CONFIRMATION)
    },
    target: onboardingSessionModel.stateReset,
  })

  // redirect to onboarding if skip onboarding
  sample({
    clock: saveProfileFx.doneData,
    filter: onboardingSessionModel.$isOnboardingSkipped,
    target: onboardingSessionModel.stateReset,
  })

  return {
    Gate,
    $$form,

    initiated,
    resentInitiated,

    saveProfileFx,
    resentConfirmationEmailFx,

    $$countdownModel,
    $isConfirmationEmailSent,
    $inSaveProfileInProgress,
    $isResentEmailInProgress,

    $error,
    $isError,
  }
})
