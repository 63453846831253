import { apiServiceNew, errorHandler } from '~/shared/api'

export const getSavedTrips = async () => {
  try {
    const res = await apiServiceNew.appClient.savedRecommendations.getSavedTrips()

    return {
      tripId: res.data?.id,
      trips: res.data?.ideas || [],
      summary: res.data?.summary || '',
    }
  } catch (res: any) {
    return errorHandler(res, 'Failed to get user saved trips')
  }
}
