import { useStore } from 'effector-react'
import { motion } from 'framer-motion'
import { FC, lazy, useEffect, useState } from 'react'
import { $animationData, animationDataRequested } from '../model'
import { styles } from '../styles'
interface Props {
  loadingMessages: string[]
  isMessagesLoading: boolean
  isNewSummaryPending: boolean
}

const LazyLottieComponent = lazy(() => import('lottie-react'))
export const PageLoader: FC<Props> = ({
  loadingMessages,
  isMessagesLoading,
  isNewSummaryPending,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const animationData = useStore($animationData)

  useEffect(() => {
    if (!animationData) {
      animationDataRequested()
    }
  }, [animationData])

  useEffect(() => {
    if (!isMessagesLoading) {
      setCurrentIndex(0)
    }

    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length)
    }, 5000)

    return () => clearInterval(intervalId)
  }, [isMessagesLoading, loadingMessages])

  return (
    <div css={styles.wrapper}>
      <div css={styles.content}>
        <div css={styles.animation}>
          {animationData ? (
            <LazyLottieComponent
              animationData={animationData}
              css={styles.lottie}
              loop={true}
            />
          ) : (
            <div style={{ height: '180px' }}></div>
          )}
        </div>
        {isMessagesLoading || isNewSummaryPending ? (
          <motion.p
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 40 }}
            transition={{ duration: 0.5 }}
            css={styles.text}
          >
            We are selecting the best recommendations for you...
          </motion.p>
        ) : (
          <motion.p
            key={currentIndex}
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 40 }}
            transition={{ duration: 0.5 }}
            css={styles.text}
          >
            {loadingMessages[currentIndex]}
          </motion.p>
        )}
      </div>
    </div>
  )
}
