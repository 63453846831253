import {
  BaseResponseDto,
  UnsubscribeEmailEngagementDto,
} from '@softcery/awayaway-nodejs-api-client'
import { apiServiceNew, errorHandler } from '~/shared/api'

export const unsubscribeEmail = async (
  fields: UnsubscribeEmailEngagementDto,
): Promise<BaseResponseDto | undefined> => {
  try {
    const res = await apiServiceNew.appClient.emailEngagement.unsubscribeEngagementEmail(
      fields,
    )

    return res
  } catch (res: any) {
    return errorHandler(res, 'Failed to unsubscribe')
  }
}
