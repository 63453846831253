import {
  browserTracingIntegration,
  captureConsoleIntegration,
  httpClientIntegration,
  init,
} from '@sentry/react'
import { useEffect } from 'react'
import { Routing } from '~/pages'
import { isDevelopmentBySentry } from '~/shared/config'
import { appStarted, appStopped } from '~/shared/config/init'
import { worker } from '~/shared/worker'
import { withHocs } from './hocs'
import './model'

if (!isDevelopmentBySentry) {
  init({
    environment: process.env.SENTRY_ENV,
    dsn: 'https://1bc7e25065eac15464d1f4e89d5f6162@o4507067570257920.ingest.us.sentry.io/4507067802189824',
    integrations: [
      browserTracingIntegration(),
      httpClientIntegration({
        failedRequestStatusCodes: [[500, 599]],
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
  })
}

const App = () => {
  appStarted()

  useEffect(() => {
    if (worker) {
      worker.port.start()
    } else {
      console.log('Your browser does not support SharedWorker.')
    }

    return () => appStopped()
  }, [])

  return <Routing />
}

export default withHocs(App)
