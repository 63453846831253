import { createFactory } from '@withease/factories'
import { combine, createEffect, createEvent, restore, sample } from 'effector'
import { TripIdeaByIdReq } from '~/entities/destination/types'
import { getErrorMessage } from '~/shared/api'
import { bridge } from '~/shared/lib/factory'
import { ManageTripIdeaFactory } from '../manage-destination'
import { getTripIdeaById } from './api'

export const getTripIdeaByIdFactory = createFactory(
  ({ $$manageTripIdeaFactory }: { $$manageTripIdeaFactory: ManageTripIdeaFactory }) => {
    const getTripIdeaByIdFx = createEffect(getTripIdeaById)

    const initialed = createEvent<TripIdeaByIdReq>()
    const getTripIdeaByIdCompleted = createEvent<boolean>()
    const resetErrors = createEvent()

    const $isGetTripIdeaByIdCompleted = restore(getTripIdeaByIdCompleted, false)

    const $getIdeaStatus = combine({
      loading: getTripIdeaByIdFx.pending,
      error: restore(getTripIdeaByIdFx.finally, null)
        .map(getErrorMessage)
        .reset(resetErrors),
    })

    bridge(() => {
      sample({
        clock: initialed,
        source: $getIdeaStatus,
        filter: (getIdeaStatus, { tripResourceId }) =>
          !getIdeaStatus.loading && tripResourceId !== 'trip-id',
        fn: (_, tripId) => tripId,
        target: getTripIdeaByIdFx,
      })

      sample({
        clock: getTripIdeaByIdFx.doneData,
        fn: () => true,
        target: getTripIdeaByIdCompleted,
      })

      sample({
        clock: getTripIdeaByIdFx.doneData,
        fn: (tripIdea) => {
          const result = tripIdea.data ?? null
          return result
        },
        target: $$manageTripIdeaFactory.selectedDestination,
      })
    })

    return {
      $getIdeaStatus,
      $isGetTripIdeaByIdCompleted,
      initialed,
      resetErrors,
      getTripIdeaByIdCompleted,
      getTripIdeaByIdFx,
    }
  },
)
