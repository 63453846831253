import { createFactory } from '@withease/factories'
import { createEffect, sample } from 'effector'
import { spread } from 'patronum'
import { ManageTripsDataFactory } from '../manage-trips-data/manage-trips-data'
import { updateTripsData } from './api'

export type GetUpdatedTripsFactory = ReturnType<typeof getUpdatedTrips>
export const getUpdatedTrips = createFactory(
  ({
    $$manageTripsDataFactory,
  }: {
    $$manageTripsDataFactory: ManageTripsDataFactory
  }) => {
    const getUpdatedTripsFx = createEffect(updateTripsData)

    sample({
      clock: getUpdatedTripsFx.doneData,
      fn: (res) => ({
        trips: res?.data?.ideas || null,
        summary: res?.data?.summary || '',
        tripId: res?.data?.id,
      }),
      target: spread({
        targets: {
          trips: $$manageTripsDataFactory.updateTrips,
          summary: $$manageTripsDataFactory.updateGreetingMessage,
          tripId: $$manageTripsDataFactory.updateTripId,
        },
      }),
    })

    return {
      getUpdatedTripsFx,
    }
  },
)
