import { createFactory } from '@withease/factories'
import { createEffect, createEvent, createStore, restore, sample } from 'effector'
import { pending, reset, spread } from 'patronum'
import { RecommendTripsForRequest } from '~/entities/trip'
import { getTripsFromRecommender } from '~/entities/trip/model/get-recommender-trips/api'
import { AppRoute } from '~/shared/config'
import { createAbortableEffect } from '~/shared/lib/abort-effect'
import { bridge } from '~/shared/lib/factory'
import { navigationModel } from '~/shared/models/navigation'
import { ManageTripsDataFactory } from '../manage-trips-data/manage-trips-data'

export type GetRecommenderTripsFactory = ReturnType<typeof getRecommenderTripsFactory>
export const getRecommenderTripsFactory = createFactory(
  ({
    $$manageTripsDataFactory,
  }: {
    $$manageTripsDataFactory: ManageTripsDataFactory
  }) => {
    const stateReset = createEvent()

    const [getTripsFx, getTripsAborted] = createAbortableEffect((abortSignal) =>
      createEffect((fields: RecommendTripsForRequest) =>
        getTripsFromRecommender(fields, abortSignal),
      ),
    )

    const $error = restore(getTripsFx.failData, null)

    const $tripsReceivingFailed = createStore(false)

    const $isTripsLoading = pending({
      effects: [getTripsFx],
      of: 'some',
    })

    // success handling
    bridge(() => {
      sample({
        clock: getTripsFx.doneData,
        fn: ({ trips, summary, tripId, comment }) => {
          return {
            trips,
            summary,
            tripId,
            comment,
            urlToChange: `${AppRoute.Destinations}/${tripId}`,
          }
        },
        target: spread({
          targets: {
            trips: $$manageTripsDataFactory.updateTrips,
            summary: $$manageTripsDataFactory.updateGreetingMessage,
            tripId: $$manageTripsDataFactory.updateTripId,
            comment: $$manageTripsDataFactory.updateExplanationMessage,
            urlToChange: navigationModel.pathReplace,
          },
        }),
      })

      sample({
        source: getTripsFx.doneData,
        fn: () => false,
        target: $tripsReceivingFailed,
      })
    })

    reset({
      clock: stateReset,
      target: [$error, $tripsReceivingFailed],
    })

    return {
      $tripsReceivingFailed,
      $isTripsLoading,
      $error,

      stateReset,
      getTripsFx,
      getTripsAborted,
    }
  },
)
