import { createEvent, sample } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'
import { scheduleTripDatesModel } from '~/features/onboarding/schedule-trip-dates'

import { $animationData, animationDataRequested } from '~/entities/loader/model'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { newRecommendationsInitiated } from '~/entities/onboarding-session/model'
import { summaryPageModel } from '../../summary'

export const Gate = createGate<void>()
export const nextStepNavigated = createEvent()
export const summaryStepSkipped = createEvent()
export const newTripsInitiated = createEvent()

sample({
  clock: Gate.open,
  target: onboardingSessionModel.scrollToTopFx,
})

// sample({
//   clock: nextStepNavigated,
//   filter: scheduleTripDatesModel.$isFlexibleOptionSelected,
//   target: onboardingSessionModel.exactDatesRemoved,
// })

// get loader
sample({
  clock: Gate.open,
  source: $animationData,
  filter: (animationData) => !animationData,
  target: animationDataRequested,
})

sample({
  clock: newTripsInitiated,
  source: {
    initialSummary: summaryPageModel.$initialSummary,
    initialSummaryPending: summaryPageModel.$isSummaryPending,
  },
  filter: ({ initialSummary, initialSummaryPending }) =>
    Boolean(initialSummary == '') && !initialSummaryPending,
  target: [newRecommendationsInitiated, summaryStepSkipped],
})

reset({
  clock: onboardingSessionModel.stepChanged,
  target: scheduleTripDatesModel.$isExactDatesDurationLimitReached,
})
