import dayjs from 'dayjs'
import { DurationUnitType } from 'dayjs/plugin/duration'

export const addDaysToDate = (date: Date, days: number): Date => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const addMonthToDate = (date: Date) => {
  const day = dayjs(date)
  return day.add(1, 'month')
}

export const formatToDate = (date: string, customFormat?: string): Date => {
  return dayjs(date, customFormat).toDate()
}

export const formatToCustom = (date: Date, customFormat: string): string => {
  return dayjs(date).format(customFormat)
}
export const formatDateStringToCustom = (
  dateString: string,
  inputFormat: string,
  outputFormat: string,
) => {
  return dayjs(dateString, inputFormat).format(outputFormat)
}

export const humanizeDuration = (duration: number, unit: DurationUnitType): string => {
  return dayjs.duration(duration, unit).humanize()
}
export const formatFromNanoseconds = (
  duration: number,
  unit: DurationUnitType,
): string => {
  return dayjs.duration(duration / 1000000, unit).humanize()
}

export const formatToUtcDate = (dateString: string): Date => {
  const endDateUtcString = new Date(dateString).toISOString().slice(0, -1)
  const utcEndDate = new Date(endDateUtcString)
  return utcEndDate
}

export const getTwoDigitMonth = (date: Date): string =>
  date.toLocaleString('en', { month: '2-digit' })

export const getFullMonth = (date: Date): string =>
  date.toLocaleString('en', { month: 'long' })

export const formatToHyphenDate = (date: Date | string): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatToSlashDate = (date: Date | string): string => {
  return dayjs(date).format('DD/MM/YYYY')
}

export const formatToYearMonth = (date: Date | string): string => {
  return dayjs(date).format('YYYY-MM')
}

export const formatToPrettyDate = (date: Date): string => {
  const parsedDate = dayjs(date)
  return parsedDate.format('Do MMMM YYYY')
}

export const getAgeFromBirth = (birthday: Date): number =>
  dayjs().diff(dayjs(birthday), 'years')

export const isDatesSame = (firstDate: Date, secondDate: Date) =>
  dayjs(firstDate).isSame(secondDate)

export const areDateRangesSame = (
  firstRange: { startDate: Date; endDate: Date },
  secondRange: { startDate: Date; endDate: Date },
) =>
  isDatesSame(firstRange.startDate, secondRange.startDate) &&
  isDatesSame(firstRange.endDate, secondRange.endDate)

export const isDateBetween = (date: Date, startDate: Date, endDate: Date) =>
  dayjs(date).isBetween(startDate, endDate, 'day')

export const getDatesDiff = (firstDate: Date, secondDate: Date) =>
  dayjs(secondDate).diff(dayjs(firstDate), 'days')

export const isValidDate = (date: Date) => date instanceof Date && !isNaN(date.getTime())

export const isDateInFuture = (date: Date) => date > new Date()

export const convertJsMonthToCalendar = (jsMonth: number) =>
  jsMonth === 12 ? 0 : jsMonth + 1

export const convertCalendarMonthToJs = (calendarMonth: number) =>
  calendarMonth === 0 ? 12 : calendarMonth - 1

export const checkIfMonthIsCurrent = (month: number, year: number) => {
  const today = new Date()
  const isMonthCurrent =
    month === convertJsMonthToCalendar(today.getMonth()) && year === today.getFullYear()
  return isMonthCurrent
}

export const isCurrentDateBefore = (date: string | Date) => {
  return dayjs().isBefore(date)
}
