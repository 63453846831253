import { createFactory } from '@withease/factories'
import { createEffect, sample } from 'effector'
import { spread } from 'patronum'
import { getSavedTrips } from '~/entities/trip/model/get-saved-trips/api'
import { ManageTripsDataFactory } from '~/entities/trip/model/manage-trips-data/manage-trips-data'
import { AppRoute } from '~/shared/config'
import { navigationModel } from '~/shared/models/navigation'

export type GetSavedTripsFactory = ReturnType<typeof getSavedTripsFactory>
export const getSavedTripsFactory = createFactory(
  ({
    $$manageTripsDataFactory,
  }: {
    $$manageTripsDataFactory: ManageTripsDataFactory
  }) => {
    const getUserSavedTripsFx = createEffect(getSavedTrips)

    sample({
      clock: getUserSavedTripsFx.doneData,
      source: getUserSavedTripsFx.pending,
      filter: (loading, { trips }) => Boolean(trips) && !loading,
      fn: (_, { trips, summary, tripId }) => {
        const urlToChange = `${AppRoute.Destinations}/${tripId}`
        return { trips, summary, tripId, urlToChange }
      },
      target: spread({
        targets: {
          trips: $$manageTripsDataFactory.updateTrips,
          summary: $$manageTripsDataFactory.updateGreetingMessage,
          tripId: $$manageTripsDataFactory.updateTripId,
          urlToChange: navigationModel.pathChanged,
        },
      }),
    })

    return {
      getUserSavedTripsFx,
    }
  },
)
