import { TripIdeaByIdReq } from '~/entities/destination/types'
import { apiErrorHandler, apiServiceNew } from '~/shared/api'

export const getTripIdeaById = async ({
  tripResourceId,
  tripIdeaId,
}: TripIdeaByIdReq): Promise<any> => {
  try {
    return await apiServiceNew.appClient.tripsRecommendations.getTripIdeaByTripIdeaId(
      tripResourceId,
      tripIdeaId,
    )
  } catch (e) {
    return apiErrorHandler(e)
  }
}
