import {
  ItineraryTripDto,
  TripIdeaResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import { format } from 'date-fns'
import { createEvent, sample, combine, restore } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset, spread } from 'patronum'
import { tripFeedbackModel } from '~/features/feedback'
import { chooseTripDatesModel } from '~/features/trip-booking/choose-trip-dates'
import {
  $availableDatesWithRates,
  $checkOutOnlyDates,
  $unavailableDates,
  bookingDatesChangeInitiated,
  calendarUpdated,
  checkedMonthReset,
} from '~/features/trip-booking/choose-trip-dates/model'
import { tripIdeaModel } from '~/entities/destination/model'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { hotelsForTripModel } from '~/entities/hotel'

import { onboardingSessionModel } from '~/entities/onboarding-session'
import { hotelRoomModel } from '~/entities/room'
import { destinationModel, SelectedDatesForAllDestinations } from '~/entities/trip'

import { tripsModel } from '~/entities/trip/model'
import { tripInfoModel } from '~/entities/trip-info'
import { viewerModel } from '~/entities/viewer'
import { LocalStorageKeys } from '~/shared/config'
import { bridge } from '~/shared/lib/factory'
import { navigationModel } from '~/shared/models/navigation'

export const DestinationDetailsGate = createGate<{
  tripSourceId: string
  destinationId: string
}>()
export const bookingInitiated = createEvent()
export const destinationViewed = createEvent<TripIdeaResponseDto | null>()

const itinerariesRequested = createEvent()
export const changeForbidDoReqForItineraries = createEvent<boolean>()
export const $isForbidDoReqForItineraries = restore(
  changeForbidDoReqForItineraries,
  false,
)

export const resetItinerariesWithRequestsDate = createEvent()
export const updateItinerariesWithRequestsDate = createEvent<
  SelectedDatesForAllDestinations[] | null
>()
export const $itinerariesWithRequestsDate = restore(
  updateItinerariesWithRequestsDate,
  null,
)

sample({
  clock: DestinationDetailsGate.open,
  target: chooseTripDatesModel.tripsDatesCheckInitiated,
})

sample({
  clock: DestinationDetailsGate.open,
  source: tripsModel.$$manageTripsDataFactory.$trips,
  fn: (trips, destinationId) => trips?.find((t) => t.id === +destinationId) || null,
  target: destinationViewed,
})

// update selected hotel code in new hotel room model
sample({
  clock: [
    hotelsForTripModel.$$manageHotelFactory.updateSelectedHotelForTrip,
    hotelsForTripModel.$$getHotelsFactory.updateHotelsRequest,
  ],
  source: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  filter: (hotelCode) => {
    return !!hotelCode
  },
  fn: (hotelCode) => hotelCode!.code!,
  target: hotelRoomModel.$$getRoomsFactory.updateSelectedHotelCode,
})

// update hotels prices
sample({
  clock: hotelRoomModel.$$manageRoomsDataFactory.$lowestTotalRateAmount,
  source: {
    hotelsFromSelectedTripWithPrices:
      hotelsForTripModel.$$manageHotelFactory.$hotelsFromSelectedTripWithPrices,
    selectedHotel: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  },
  filter: ({ selectedHotel }, lowestTotalRateAmount) =>
    !!selectedHotel?.code && !!lowestTotalRateAmount,
  fn: ({ hotelsFromSelectedTripWithPrices, selectedHotel }, lowestTotalRateAmount) => {
    const selectedHotelCode = selectedHotel?.code
    if (!hotelsFromSelectedTripWithPrices)
      return { [selectedHotelCode!]: lowestTotalRateAmount }

    const copyOfHotels = {
      ...hotelsFromSelectedTripWithPrices,
      [selectedHotelCode!]: lowestTotalRateAmount,
    }
    return copyOfHotels
  },
  target: hotelsForTripModel.$$manageHotelFactory.updateHotelsFromSelectedTrioWithPrices,
})

// sample({
//   clock: DestinationDetailsGate.open,
//   source: {
//     trips: tripsModel.$$manageTripsDataFactory.$trips,
//     selectedTrip: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
//   },
//   filter: ({ selectedTrip, trips }, { destinationId }) => {
//     const isTripExist = trips?.find((t) => t.id === +destinationId)
//     return selectedTrip?.id !== +destinationId && !!isTripExist
//   },
//   fn: ({ trips }, { destinationId }) => {
//     return trips?.find((t) => t.id === +destinationId) || null
//   },
//   target: tripIdeaModel.$$manageTripIdeaFactory.selectedDestination,
// })

sample({
  clock: DestinationDetailsGate.open,
  source: {
    trips: tripsModel.$$manageTripsDataFactory.$trips,
    selectedTrip: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
    isAuth: viewerModel.$isAuthorized,
  },
  fn: (_, { tripSourceId, destinationId }) => {
    return {
      tripIdeaReq: { tripResourceId: tripSourceId, tripIdeaId: +destinationId },
      tripResourceId: tripSourceId,
    }
  },
  target: spread({
    targets: {
      tripIdeaReq: tripIdeaModel.$$getTripIdeaByIdFactory.initialed,
      tripResourceId: tripsModel.$$getTripsBySourceIdFactory.initiate,
    },
  }),
})

sample({
  clock: tripIdeaModel.$$getTripIdeaByIdFactory.getTripIdeaByIdFx.doneData,
  fn: (trip) => trip.data,
  target: tripInfoModel.updateGuestsInitiated,
})

sample({
  clock: destinationModel.regenerateTripItinerariesWhenDateChanged,
  source: {
    isRoomsListEmpty: hotelRoomModel.$$manageRoomsDataFactory.$isRoomsListEmpty,
    selectedDestination: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
  },
  filter: ({ isRoomsListEmpty }) => isRoomsListEmpty,
  fn: ({ selectedDestination }) => selectedDestination?.destinationCode || '',
  target: destinationModel.resetItineraryFromAllItineraries,
})

sample({
  clock: bookingInitiated,
  source: hotelsForTripModel.$$manageHotelFactory.$selectedHotelForTrip,
  filter: (selectedHotel) => !!selectedHotel?.code,
  fn: (hotel) => `${hotel!.code!}/contacts`,
  target: navigationModel.pathPushed,
})

reset({
  clock: DestinationDetailsGate.close,
  target: tripFeedbackModel.$selectedTripFeedback,
})

bridge(() => {
  // open/close destination details gate
  sample({
    clock: [
      chooseTripDatesModel.$selectedDateForDestinations,
      destinationModel.regenerateTripItinerariesWhenDateChanged,
    ],
    source: {
      itinerariesWithRequestsDate: $itinerariesWithRequestsDate,
      selectedDestination: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
      isCalendarVisible: $isForbidDoReqForItineraries,
      selectedDate: chooseTripDatesModel.$selectedDateForDestinations,
    },
    filter: ({
      itinerariesWithRequestsDate,
      selectedDestination,
      isCalendarVisible,
      selectedDate,
    }) => {
      if (isCalendarVisible) return false
      if (!selectedDate?.length) return false
      if (!itinerariesWithRequestsDate) return true

      const getDestinationSelectedDate = selectedDate.find(
        (dest) => dest.destinationId === selectedDestination?.id,
      )
      if (!getDestinationSelectedDate) return false

      const ifDestinationCodeExist = itinerariesWithRequestsDate.find(
        (dest) => dest.destinationId === selectedDestination?.id,
      )
      if (!ifDestinationCodeExist) return true

      const isDateMatch =
        ifDestinationCodeExist.startDate === getDestinationSelectedDate.startDate &&
        ifDestinationCodeExist.endDate === getDestinationSelectedDate.endDate
      return !isDateMatch
    },

    target: itinerariesRequested,
  })

  sample({
    clock: itinerariesRequested,
    source: chooseTripDatesModel.$selectedDateForDestinations,
    target: updateItinerariesWithRequestsDate,
  })

  // get itineraries when destination is selected
  sample({
    clock: itinerariesRequested,
    source: {
      categories: listImageRecommendationsModel.$imagesLabels,
      trip: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
      dates: tripInfoModel.$selectedTripDates,
      travellers: combine(
        tripInfoModel.$adultsAmount,
        tripInfoModel.$childrenAmount,
        tripInfoModel.$childrenAges,
        (adults, children, childrenAges) => ({
          adults,
          children,
          childrenAges,
        }),
      ),
    },
    fn: ({ categories, trip, travellers, dates }) => {
      return {
        destinationCode: trip?.destinationCode || '',
        fields: {
          categories,
          trip: {
            country: trip?.countryName || '',
            date: {
              startDate: format(new Date(dates.startDate), 'yyyy-MM-dd') || '',
              endDate: format(new Date(dates.endDate), 'yyyy-MM-dd') || '',
            },
            description: trip?.description || '',
            keypoints: trip?.keypoints || '',
            name: trip?.name || '',
            travellers,
          } as ItineraryTripDto,
        },
      }
    },
    target: destinationModel.getTripItinerariesFx,
  })

  // reset selected dates when destination is changed
  sample({
    clock: tripIdeaModel.$$manageTripIdeaFactory.$selectedDestination,
    filter: (selectedDestination) => !selectedDestination,
    target: tripInfoModel.selectedDatesReset,
  })

  // sample({
  //   clock: DestinationDetailsGate.close,
  //   fn: () => false,
  //   target: tripIdeaModel.$$getTripIdeaByIdFactory.getTripIdeaByIdCompleted,
  // })
})

sample({
  clock: DestinationDetailsGate.open,
  source: {
    onboardingDates: onboardingSessionModel.$savedDates,
    currentBookingDates: tripInfoModel.$selectedTripDates,
  },
  fn: ({ onboardingDates, currentBookingDates }) => {
    return currentBookingDates.startDate !== '' && currentBookingDates.endDate !== ''
      ? currentBookingDates
      : onboardingDates
  },
  target: bookingDatesChangeInitiated,
})

sample({
  clock: tripIdeaModel.$$manageTripIdeaFactory.selectedDestination,
  filter: (selectedTrip) => !!selectedTrip?.dates?.length,
  fn: (selectedTrip) => ({
    startDate: selectedTrip!.dates![0].start,
    endDate: selectedTrip!.dates![0].end,
  }),
  target: bookingDatesChangeInitiated,
})

sample({
  clock: tripIdeaModel.$$manageTripIdeaFactory.selectedDestination,
  filter: (destination) => !!destination,
  fn: (selectedTrip) => {
    return selectedTrip?.hotels || []
  },
  target: hotelsForTripModel.$$getHotelsFactory.updateHotelsRequest,
})

sample({
  clock: tripIdeaModel.$$getTripIdeaByIdFactory.getTripIdeaByIdFx.failData,
  target: tripsModel.$$manageNavigationForTripsFactory.navigateToDestinationsPage,
})

// ------
reset({
  clock: [DestinationDetailsGate.close, calendarUpdated],
  target: [
    hotelRoomModel.$$manageRoomsDataFactory.$getRoomsStatus,
    $availableDatesWithRates,
    $checkOutOnlyDates,
    $unavailableDates,
  ],
})

sample({
  clock: DestinationDetailsGate.close,
  target: checkedMonthReset,
})

persist({
  store: $itinerariesWithRequestsDate,
  key: LocalStorageKeys.SelectedDateWhenRequestDoForItinerary,
})

reset({
  clock: [destinationModel.stateReset, tripsModel.resetState],
  target: $itinerariesWithRequestsDate,
})
