import { createFactory } from '@withease/factories'
import { createEvent, sample } from 'effector'
import { format } from 'patronum/format'
import { reset } from 'patronum/reset'
import { ManageTripsDataFactory } from '~/entities/trip/model/manage-trips-data/manage-trips-data'
import { AppRoute } from '~/shared/config'
import { navigationModel } from '~/shared/models/navigation'
export const manageNavigationForTripsFactory = createFactory(
  ({
    $$manageTripsDataFactory,
  }: {
    $$manageTripsDataFactory: ManageTripsDataFactory
  }) => {
    const navigateToDestinationsPage = createEvent()

    const resetState = createEvent()

    const $pathToDestinationsPage = format`${AppRoute.Destinations}/${$$manageTripsDataFactory.$tripId}`

    sample({
      clock: navigateToDestinationsPage,
      source: $$manageTripsDataFactory.$tripId,
      fn: (tripId) => `${AppRoute.Destinations}/${tripId ? tripId : 'trip-id'}`,
      target: navigationModel.pathChanged,
    })

    reset({
      clock: resetState,
      target: $pathToDestinationsPage,
    })

    return {
      $pathToDestinationsPage,

      navigateToDestinationsPage,
      resetState,
    }
  },
)
