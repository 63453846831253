import { createFactory } from '@withease/factories'
import { createEffect, createEvent, sample, restore } from 'effector'
import { reset, spread } from 'patronum'
import { bridge } from '~/shared/lib/factory'
import { TripSourceId } from '../../types'
import { ManageTripsDataFactory } from '../manage-trips-data/manage-trips-data'
import { getTripsBySourceId } from './api'

export type GetTripsBySourceIdFactory = ReturnType<typeof getTripsBySourceIdFactory>
export const getTripsBySourceIdFactory = createFactory(
  ({
    $$manageTripsDataFactory,
  }: {
    $$manageTripsDataFactory: ManageTripsDataFactory
  }) => {
    const resetError = createEvent()
    const resetState = createEvent()
    const initiate = createEvent<TripSourceId>()
    const getTripsBySourceIdFx = createEffect(getTripsBySourceId)

    const $isPending = getTripsBySourceIdFx.pending
    const updateError = createEvent<string | null>()
    const $error = restore(updateError, null)

    bridge(() => {
      sample({
        clock: initiate,
        source: {
          savedSourceId: $$manageTripsDataFactory.$tripId,
          trips: $$manageTripsDataFactory.$trips,
          isPending: $isPending,
        },
        filter: ({ savedSourceId, trips, isPending }, sourceId) => {
          return (
            (sourceId !== savedSourceId || !trips?.length) && !isPending && !!sourceId
          )
        },
        fn: (_, sourceId) => ({
          tripSourceId: sourceId,
          // resetTrips: null ,
        }),
        target: spread({
          targets: {
            tripSourceId: getTripsBySourceIdFx,
          },
        }),
      })

      sample({
        source: getTripsBySourceIdFx.doneData,
        fn: (res) => ({
          trips: res?.data?.ideas || null,
          summary: res?.data?.summary,
          tripId: res?.data?.id,
        }),
        target: spread({
          targets: {
            trips: $$manageTripsDataFactory.updateTrips,
            summary: $$manageTripsDataFactory.updateGreetingMessage,
            tripId: $$manageTripsDataFactory.updateTripId,
          },
        }),
      })
    })

    // error handling
    bridge(() => {
      sample({
        source: getTripsBySourceIdFx.failData,
        fn: (error) => {
          return { error: error.message as string, resetTripId: 'trip-id' }
        },
        target: spread({
          targets: {
            error: updateError,
            // resetTripId: $$manageTripsDataFactory.updateTripId,
          },
        }),
      })
    })

    reset({
      clock: resetError,
      target: $error,
    })

    return {
      $isPending,
      $error,

      initiate,
      resetState,
      updateError,
      resetError,

      getTripsBySourceIdFx,
    }
  },
)
