import { createFactory } from '@withease/factories'
import { createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset, spread } from 'patronum'
import { selectHotelAndAddIsSelectedField } from '~/entities/hotel'
import { GetHotelsFactory } from '~/entities/hotel/model/get-hotels'
import { HotelsWithPrices, MutatedTripIdea } from '~/entities/hotel/types'
import { LocalStorageKeys } from '~/shared/config'
import { bridge } from '~/shared/lib/factory'

export const manageHotelFactory = createFactory(
  ({ $$getHotelsFactory }: { $$getHotelsFactory: GetHotelsFactory }) => {
    const resetState = createEvent()

    const updateSelectedHotelForTrip = createEvent<MutatedTripIdea>()
    const $selectedHotelForTrip = createStore<MutatedTripIdea | null>(null)

    const updateHotelsForTrip = createEvent<MutatedTripIdea[]>()
    const $hotelsForTrip = restore(updateHotelsForTrip, null)

    const updateHotelsFromSelectedTrioWithPrices = createEvent<HotelsWithPrices>()
    const $hotelsFromSelectedTripWithPrices = restore(
      updateHotelsFromSelectedTrioWithPrices,
      null,
    )

    bridge(() => {
      sample({
        clock: $$getHotelsFactory.updateHotelsRequest,
        filter: (hotels) => {
          return !!hotels?.length
        },
        fn: (hotels) => selectHotelAndAddIsSelectedField(hotels, null),
        target: spread({
          targets: {
            selectedOne: $selectedHotelForTrip,
            hotelsWithSelectedOne: updateHotelsForTrip,
          },
        }),
      })

      sample({
        clock: updateSelectedHotelForTrip,
        source: $hotelsForTrip,
        filter: (hotels) => {
          return !!hotels?.length
        },
        fn: (hotels, selectedHotel) =>
          selectHotelAndAddIsSelectedField(hotels!, selectedHotel),
        target: spread({
          targets: {
            selectedOne: $selectedHotelForTrip,
            hotelsWithSelectedOne: updateHotelsForTrip,
          },
        }),
      })
    })

    persist({ store: $selectedHotelForTrip, key: LocalStorageKeys.SelectedHotel })
    persist({ store: $hotelsForTrip, key: LocalStorageKeys.HotelsForTrip })

    reset({
      clock: resetState,
      target: [$selectedHotelForTrip, $hotelsForTrip, $hotelsFromSelectedTripWithPrices],
    })

    return {
      $selectedHotelForTrip,
      $hotelsForTrip,
      $hotelsFromSelectedTripWithPrices,

      updateHotelsFromSelectedTrioWithPrices,
      resetState,
      updateSelectedHotelForTrip,
      updateHotelsForTrip,
    }
  },
)
