import { LoginUserRequestBody } from '@softcery/awayaway-apiclient'
import { createEffect, restore, sample, createEvent } from 'effector'
import { createGate } from 'effector-react'
import { reset, spread } from 'patronum'

import { onboardingSessionModel } from '~/entities/onboarding-session'
import { popupModel } from '~/entities/popup'
import { destinationModel } from '~/entities/trip'
import { viewerModel } from '~/entities/viewer'
import { apiService, errorHandler, updateApiServiceToken } from '~/shared/api'

import { AppRoute, ManageUrlQueryKeys } from '~/shared/config'
import { navigationModel } from '~/shared/models/navigation'
import { getLoginErrorMessage } from '../lib'

export const Gate = createGate()

export const loginErrorUpdated = createEvent<string>()

export const loginProfileFx = createEffect(async (fields: LoginUserRequestBody) => {
  try {
    const response = await apiService().loginUser({ fields })

    updateApiServiceToken(response.accessToken!)
    return response
  } catch (res: any) {
    return errorHandler(res)
  }
})

export const getLoggedInUserFx = createEffect(async () => {
  try {
    const { user } = await apiService().getUser()
    return user!
  } catch (res: any) {
    return errorHandler(res)
  }
})

export const $loginError = restore(loginErrorUpdated, null)

// login
spread({
  source: loginProfileFx.doneData,
  targets: {
    accessToken: viewerModel.accessTokenUpdated,
    refreshToken: viewerModel.refreshTokenUpdated,
  },
})

sample({
  clock: loginProfileFx.done,
  target: popupModel.popupHidden,
})

sample({
  clock: loginProfileFx.doneData,
  target: getLoggedInUserFx,
})

sample({
  clock: getLoggedInUserFx.doneData,
  target: viewerModel.updated,
})

sample({
  clock: loginProfileFx.doneData,
  source: onboardingSessionModel.$bookingConfirmation,
  filter: (bookingConfirmation) => !!bookingConfirmation,
  fn: (bookingConfirmation) => {
    localStorage.removeItem(ManageUrlQueryKeys.BOOKING_CONFIRMATION)
    return `${AppRoute.AccountBookings}/${bookingConfirmation}`
  },
  target: [navigationModel.pathChanged, destinationModel.openTripDetailsFromEmail],
})

sample({
  clock: loginProfileFx.failData,
  fn: (error) => getLoginErrorMessage(error),
  target: loginErrorUpdated,
})

reset({
  clock: [Gate.close, loginProfileFx],
  target: $loginError,
})
