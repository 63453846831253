import { TripIdeaResponseDto } from '@softcery/awayaway-nodejs-api-client'
import dayjs from 'dayjs'
import { combine, createEffect, createEvent, sample, split } from 'effector'
import { createGate } from 'effector-react'
import { reset } from 'patronum'

import { summaryPageModel } from '~/pages/onboarding/summary'
import {
  flexibleDateOptionToStringDate,
  scheduleTripDatesModel,
} from '~/features/onboarding/schedule-trip-dates'
import { selectBudgetModel } from '~/features/onboarding/select-budget'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { searchFlightsModel } from '~/features/search-flights/model'
import { selectFlightsServicesModel } from '~/features/select-services/model'
import { chooseTripDatesModel } from '~/features/trip-booking/choose-trip-dates'
import { loginProfileModel } from '~/features/user/login-profile'
import { getLoggedInUserFx } from '~/features/user/login-profile/model'
import { logoutModel } from '~/features/user/logout'
import { closeWindow } from '~/features/user/oauth/lib'
import { oauthModel } from '~/features/user/oauth/model'
import { saveProfileModel } from '~/features/user/save-profile'
import { marketingEmailsAllowInitiated } from '~/features/user/update-personal-info/model'
import { tripIdeaModel } from '~/entities/destination/model'
import { listImageRecommendationsModel } from '~/entities/holiday'
import { hotelsForTripModel } from '~/entities/hotel'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { newRecommendationsInitiated } from '~/entities/onboarding-session/model'
import { paginationModelFactory } from '~/entities/pagination'
import { popupModel, PopupType } from '~/entities/popup'
import { hotelRoomModel } from '~/entities/room'
import { tripsModel } from '~/entities/trip/model'
import { tripInfoModel } from '~/entities/trip-info'
import { viewerModel } from '~/entities/viewer'
import { apiService } from '~/shared/api'
import { analyticsModel } from '~/shared/api/analytics'
import { isStaging } from '~/shared/config'
import { appStarted } from '~/shared/config/init'
import { formatToHyphenDate, isDatesSame, addDaysToDate } from '~/shared/lib/date'
import { atom, bridge } from '~/shared/lib/factory'
import { navigationModel } from '~/shared/models/navigation'
import { PAGINATION_PAGE_SIZE } from '../config'
import { paginateArray } from '../lib'
interface SavePriceCategoryParams {
  priceCategory: string
  referral: string
}

//TODO: refactor model by creating features and splitting logic
export const destinationsPageModel = atom(() => {
  const Gate = createGate<{
    tripSourceId: string
  }>()

  const $$pagination = paginationModelFactory()

  const moreInfoRequested = createEvent<TripIdeaResponseDto>()
  const newRecommendationsRequested = createEvent()

  const recommendationsReceivingFailed = createEvent<Error>()

  const saveNewInfoInitiated = createEvent()
  const saveUserDestinationsInitiated = createEvent()
  const savePriceCategoryInitiated = createEvent()

  const destinationsViewed = createEvent<{
    page: number
    destinations: TripIdeaResponseDto[]
  }>()

  const regenerateTripsInitiated = createEvent()
  const backToQuizNavigated = createEvent()
  const openMessengerInitiated = createEvent()

  const saveChosenPriceCategoryFx = createEffect(
    async ({ priceCategory, referral }: SavePriceCategoryParams) => {
      return apiService().changeUser({
        fields: {
          settings: {
            onboarding: {
              priceCategory: priceCategory,
            },
          },
          referral: referral,
        },
      })
    },
  )

  const closeWindowFx = createEffect(closeWindow)

  const $displayedTrips = combine(
    tripsModel.$$manageTripsDataFactory.$trips,
    $$pagination.$offset,
    (trips, offset) => paginateArray(trips ?? [], offset, PAGINATION_PAGE_SIZE),
  )

  //handle page opening
  bridge(() => {
    //TODO: structure rules in readable way
    sample({
      clock: [Gate.open, tripsModel.$$getRecommenderTripsFactory.getTripsFx],
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        isLoggedOut: logoutModel.$isLogouted,
        isOnboardingSkipped: onboardingSessionModel.$isOnboardingSkipped,
        visiblePopup: popupModel.$visiblePopup,
      },
      filter: ({ isAuthorized, visiblePopup }) => !isAuthorized && !visiblePopup,
      fn: ({ isLoggedOut, isOnboardingSkipped }) =>
        isLoggedOut || isOnboardingSkipped ? PopupType.Login : PopupType.Register,
      target: popupModel.visiblePopupChanged,
    })

    sample({
      clock: newRecommendationsInitiated,
      target: newRecommendationsRequested,
    })

    sample({
      clock: Gate.open,
      target: chooseTripDatesModel.tripsDatesCheckInitiated,
    })

    sample({
      clock: Gate.open,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        destinations: tripsModel.$$manageTripsDataFactory.$trips,
        isReOnboardingProcess: onboardingSessionModel.$isReOnboardingProcess,
        gateState: Gate.state,
        isRecommenderError: tripsModel.$$getRecommenderTripsFactory.$error,
      },
      filter: ({
        isAuthorized,
        destinations,
        isReOnboardingProcess,
        isRecommenderError,
        gateState,
      }) => {
        return (
          isAuthorized &&
          !destinations?.length &&
          !isReOnboardingProcess &&
          !isRecommenderError &&
          gateState.tripSourceId === 'trip-id'
        )
      },
      target: tripsModel.$$getSavedTripsFactory.getUserSavedTripsFx,
    })

    sample({
      clock: tripsModel.$$getSavedTripsFactory.getUserSavedTripsFx.doneData,
      fn: (res) => res.trips[0],
      target: tripInfoModel.updateGuestsInitiated,
    })

    sample({
      clock: Gate.open,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        isReOnboardingProcess: onboardingSessionModel.$isReOnboardingProcess,
        gateState: Gate.state,
        isRecommenderError: tripsModel.$$getRecommenderTripsFactory.$error,
      },
      filter: ({
        isAuthorized,
        isReOnboardingProcess,
        isRecommenderError,
        gateState,
      }) => {
        return (
          isAuthorized &&
          !isReOnboardingProcess &&
          !isRecommenderError &&
          gateState.tripSourceId !== 'trip-id'
        )
      },
      fn: ({ gateState }) => gateState.tripSourceId,

      target: tripsModel.$$getTripsBySourceIdFactory.initiate,
    })

    sample({
      clock: appStarted,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        destinations: tripsModel.$$manageTripsDataFactory.$trips,
      },
      filter: ({ isAuthorized, destinations }) => isAuthorized && !!destinations?.length,
      fn: () => true,
      target: onboardingSessionModel.$onboardingFinished,
    })

    sample({
      clock: Gate.open,
      filter: oauthModel.$isLoggedInByOAuth,
      target: getLoggedInUserFx,
    })

    sample({
      clock: Gate.close,
      target: marketingEmailsAllowInitiated,
    })
  })

  //handle getting new recommendations
  bridge(() => {
    sample({
      clock: newRecommendationsRequested,
      source: {
        categories: listImageRecommendationsModel.$imagesLabels,
        selectedLocations: selectLocationsModel.$selectedLocations,
        rooms: tripInfoModel.$tripInfo,
        selectedBudget: selectBudgetModel.$selectedBudget,
        message: summaryPageModel.$currentSummary,

        // availability
        selectedTab: scheduleTripDatesModel.$activeTab,
        startDate: onboardingSessionModel.$exactDateStart,
        endDate: onboardingSessionModel.$exactDateEnd,
        selectedDaysNearby: scheduleTripDatesModel.$selectedDaysNearby,
        stayDuration: scheduleTripDatesModel.$stayDuration,
        flexibleDates: scheduleTripDatesModel.$flexibleDates,
      },
      fn: ({
        categories,
        selectedLocations,
        rooms,
        selectedBudget,
        startDate,
        endDate,
        selectedDaysNearby,
        stayDuration,
        flexibleDates,
        message,
      }) => {
        const isExactDates = !!startDate && !!endDate

        const dates = isExactDates
          ? {
              checkIn: formatToHyphenDate(startDate),

              //if dates are same put next day as checkout
              checkOut: formatToHyphenDate(
                isDatesSame(new Date(startDate), new Date(endDate))
                  ? addDaysToDate(new Date(endDate), 1)
                  : endDate,
              ),
              shift: selectedDaysNearby.option,
            }
          : {
              onlyWeekend: stayDuration.option === 0,
              duration: stayDuration.option,
              dates: flexibleDates?.map(({ option }) =>
                flexibleDateOptionToStringDate(option),
              ),
              shift: 5,
            }

        return {
          message,
          categories,
          ...(selectedBudget?.category && { priceCategory: selectedBudget?.category }),
          locations: selectedLocations,
          stay: {
            occupancies: rooms.map((room) => ({
              adults: room.adults.length,
              childrenAges: room.children.length
                ? room.children.map(({ age }) => age)
                : undefined,
              children: room.children.length || undefined,
              rooms: 1,
            })),
            ...dates,
          },
          //TODO: remove any type
        } as any
      },
      target: tripsModel.$$getRecommenderTripsFactory.getTripsFx,
    })

    sample({
      clock: tripsModel.$$getRecommenderTripsFactory.getTripsFx.doneData,
      target: onboardingSessionModel.onboardingFinished,
    })

    sample({
      clock: tripsModel.$$getRecommenderTripsFactory.getTripsFx.doneData,
      filter: scheduleTripDatesModel.$isFlexibleOptionSelected,
      target: onboardingSessionModel.exactDatesRemoved,
    })

    sample({
      clock: [
        tripsModel.$$getRecommenderTripsFactory.getTripsFx.doneData,
        tripsModel.$$manageTripsDataFactory.$trips,
      ],
      source: {
        trips: tripsModel.$$manageTripsDataFactory.$trips,
      },
      filter: scheduleTripDatesModel.$isFlexibleOptionSelected,
      fn: ({ trips }) => {
        return {
          startDate: dayjs(trips?.[0]?.dates?.[0]?.start).toISOString() || '',
          endDate: dayjs(trips?.[0]?.dates?.[0]?.end).toISOString() || '',
        }
      },

      target: onboardingSessionModel.exactDatesSaved,
    })

    sample({
      clock: tripsModel.$$getRecommenderTripsFactory.getTripsFx.failData,
      filter: (error) => error.message === 'No_trip_ideas: bad request',
      target: onboardingSessionModel.onboardingFinished,
    })
  })

  //handle saving new recommendations
  bridge(() => {
    sample({
      clock: [
        tripsModel.$$getRecommenderTripsFactory.getTripsFx.doneData,
        oauthModel.userDataUpdated,
      ],
      target: saveNewInfoInitiated,
    })

    sample({
      clock: saveNewInfoInitiated,
      source: {
        destinations: tripsModel.$$manageTripsDataFactory.$trips,
        isAuthorized: viewerModel.$isAuthorized,
      },
      filter: ({ destinations, isAuthorized }) =>
        !!destinations?.length && !!isAuthorized,
      target: [saveUserDestinationsInitiated, savePriceCategoryInitiated],
    })

    sample({
      clock: saveUserDestinationsInitiated,
      source: {
        trips: tripsModel.$$manageTripsDataFactory.$trips,
        tripId: tripsModel.$$manageTripsDataFactory.$tripId,
        isAuthorized: viewerModel.$isAuthorized,
      },
      filter: ({ trips, isAuthorized }) => Boolean(trips?.length) && isAuthorized,
      fn: ({ tripId }) => {
        return {
          trip: {
            tripId: tripId!,
          },
        }
      },
      target: tripsModel.$$saveUserTripsFactory.initiated,
    })

    sample({
      clock: savePriceCategoryInitiated,
      source: {
        choosedPriceCategory: tripInfoModel.$choosedPriceCategory,
        referralCode: viewerModel.$referralCode,
      },
      fn: ({ choosedPriceCategory, referralCode }) => ({
        priceCategory: choosedPriceCategory || 'any',
        referral: referralCode || '',
      }),
      target: saveChosenPriceCategoryFx,
    })

    sample({
      clock: saveChosenPriceCategoryFx.doneData,
      fn: (data) => data.user!,
      target: viewerModel.updated,
    })

    sample({
      clock: saveChosenPriceCategoryFx.doneData,
      source: viewerModel.$referralCode,
      filter: (referralCode) => referralCode != '',
      fn: (_, { user }) => ({
        id: user!.email!,
        properties: {
          ref: user?.referral,
        },
      }),
      target: analyticsModel.identify,
    })

    sample({
      clock: saveChosenPriceCategoryFx.doneData,
      target: viewerModel.referralCodeReset,
    })

    sample({
      clock: viewerModel.updated,
      filter: oauthModel.$isLoggedInByOAuth,
      target: closeWindowFx,
    })

    sample({
      clock: tripsModel.$$saveUserTripsFactory.saveUserTripsFx.finally,
      source: onboardingSessionModel.$isAppOpenedInIframe,
      filter: (isAppOpenedInIframe) => isAppOpenedInIframe,
      fn: () =>
        isStaging ? 'https://app.stag.awayaway.com' : 'https://app.awayaway.com',
      target: onboardingSessionModel.exitIframeModeFx,
    })
  })

  bridge(() => {
    sample({
      clock: tripsModel.$$getSavedTripsFactory.getUserSavedTripsFx.doneData,
      filter: ({ trips }) => !Boolean(trips.length),
      target: onboardingSessionModel.stateReset,
    })

    sample({
      clock: tripsModel.$$getSavedTripsFactory.getUserSavedTripsFx.failData,
      target: onboardingSessionModel.stateReset,
    })
  })

  //handle successful login
  bridge(() => {
    split({
      source: onboardingSessionModel.$isOnboardingSkipped,
      clock: loginProfileModel.loginProfileFx.doneData,
      match: (isOnboardingSkipped: boolean) =>
        isOnboardingSkipped ? 'getSavedDestinations' : 'saveNewDestinations',
      cases: {
        saveNewDestinations: saveNewInfoInitiated,
      },
    })
  })

  //handle successful registration
  bridge(() => {
    sample({
      clock: saveProfileModel.saveProfileFx.doneData,
      target: saveNewInfoInitiated,
    })
  })
  // handle single destination select
  bridge(() => {
    sample({
      clock: tripIdeaModel.$$manageTripIdeaFactory.selectedDestination,
      source: {
        isOpenPage: Gate.status,
      },
      filter: ({ isOpenPage }, trip) => Boolean(trip) && isOpenPage,
      fn: (_, trip) => trip!,
      target: moreInfoRequested,
    })

    sample({
      clock: moreInfoRequested,
      source: {
        pathToDestinationsPage:
          tripsModel.$$manageNavigationForTripsFactory.$pathToDestinationsPage,
        isOpenPage: Gate.status,
      },
      filter: ({ isOpenPage }) => isOpenPage,
      fn: ({ pathToDestinationsPage }, destination) =>
        `${pathToDestinationsPage}/${destination.id}`,
      target: navigationModel.pathChanged,
    })
  })

  //handle onboarding session process
  bridge(() => {
    sample({
      clock: tripsModel.$$saveUserTripsFactory.saveUserTripsFx,
      target: onboardingSessionModel.reOnboardingProcessFinished,
    })

    sample({
      clock: Gate.open,
      target: tripInfoModel.selectedDatesReset,
    })

    sample({
      clock: onboardingSessionModel.statusReset,
      target: popupModel.popupHidden,
    })

    //reset
    reset({
      clock: onboardingSessionModel.reOnboardingInitiated,
      target: [$$pagination.$currentPage, $$pagination.$offset],
    })
  })

  //handle destinations view
  bridge(() => {
    sample({
      clock: [
        Gate.status,
        $displayedTrips,
        tripsModel.$getTripsStatus,
        viewerModel.$isAuthorized,
      ],
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        destinations: $displayedTrips,
        isOpened: Gate.status,
        status: tripsModel.$getTripsStatus,
        currentPage: $$pagination.$currentPage,
      },
      filter: ({ isAuthorized, destinations, isOpened, status }) =>
        isAuthorized &&
        !!destinations.length &&
        isOpened &&
        !status.loading &&
        !status.error,
      fn: ({ currentPage, destinations }) => ({
        page: currentPage,
        destinations: destinations,
      }),
      target: destinationsViewed,
    })
  })

  sample({
    clock: Gate.open,
    target: [
      hotelsForTripModel.resetAllState,
      searchFlightsModel.stateReset,
      selectFlightsServicesModel.$$manageUiForServicesOptionsFactory.resetSeatsToDefault,
      selectFlightsServicesModel.$$manageServiceFlightDetailsFactory.resetFlightsOptions,
      hotelRoomModel.$$getRoomsFactory.stateReset,
    ],
  })

  bridge(() => {
    sample({
      clock: [navigationModel.pathChanged, onboardingSessionModel.pageChanged],
      filter: tripsModel.$$getRecommenderTripsFactory.$isTripsLoading,
      target: tripsModel.$$getRecommenderTripsFactory.getTripsAborted,
    })
  })

  return {
    Gate,
    $$pagination,
    $displayedTrips,

    moreInfoRequested,
    newRecommendationsRequested,
    saveUserDestinationsInitiated,
    recommendationsReceivingFailed,
    saveNewInfoInitiated,
    destinationsViewed,
    regenerateTripsInitiated,
    backToQuizNavigated,
    openMessengerInitiated,
    saveChosenPriceCategoryFx,
  }
})
