import { isiOS, isSafari } from '~/shared/lib/is-ios'

let openedWindow: Window | null = null

export const redirectToOauth = async (url: string) =>
  await new Promise((resolve) => {
    if (isSafari && isiOS) {
      window.location.replace(url)
    } else if (isSafari) {
      const a = document.createElement('a')
      document.body.appendChild(a)

      a.href = url
      a.target = '_blank'

      a.click()

      document.body.removeChild(a)
    } else {
      openedWindow = window.open(url, '_blank')
    }
    resolve('redirectToOauthFx')
  })

export const closeWindow = async () =>
  await new Promise((resolve) => {
    setTimeout(() => {
      if (isSafari) {
        window.close()
      } else {
        if (openedWindow) {
          openedWindow.close()
        } else {
          window.close()
        }
      }

      resolve('closed')
    }, 100)
  })
