import { createFactory } from '@withease/factories'
import { el } from 'date-fns/locale'
import { createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { TripIdeaRes } from '~/entities/trip'
import { LocalStorageKeys } from '~/shared/config'

const explanationMessageMap: Record<string, string> = {
  NO_DESTINATIONS_FOUND_FOR_NEARBY_LOCATIONS:
    "We couldn't find any destinations near selected location. Please review the suggested options or consider redoing the onboarding for more accurate results.",
  HOTELS_UNAVAILABLE_FOR_DESTINATIONS:
    'No hotels are available for the selected destinations. Please check the suggested options or try repeating the onboarding for better results.',
  LOW_RELEVANCY_TRIPS:
    "The available trips don't match your preferences. Review the suggested trips or consider redoing the onboarding for more tailored recommendations.",
}

export type ManageTripsDataFactory = ReturnType<typeof manageTripsDataFactory>
export const manageTripsDataFactory = createFactory(() => {
  const resetState = createEvent()

  const updateTrips = createEvent<TripIdeaRes[] | null>()
  const $trips = restore<TripIdeaRes[] | null>(updateTrips, null)

  const updateTripId = createEvent<string | null>()
  const $tripId = restore(updateTripId, 'trip-id')

  const updateGreetingMessage = createEvent<string | undefined>()
  const $greetingMessage = restore<string | undefined>(updateGreetingMessage, '')

  const updateExplanationMessage = createEvent<string | undefined>()
  const resetExplanationMessage = createEvent()
  const $explanationMessage = createStore<string | undefined>('')

  sample({
    clock: updateExplanationMessage,
    fn: (explanationMessage) => {
      if (!explanationMessage) return ''
      return explanationMessageMap[explanationMessage] || ''
    },
    target: $explanationMessage,
  })

  reset({
    clock: resetExplanationMessage,
    target: $explanationMessage,
  })

  reset({
    clock: resetState,
    target: [$trips, $tripId, $greetingMessage, $explanationMessage],
  })

  persist({ store: $trips, key: LocalStorageKeys.Trips })
  persist({ store: $tripId, key: LocalStorageKeys.TripId })
  persist({ store: $greetingMessage, key: LocalStorageKeys.GreetingMessage })
  persist({ store: $explanationMessage, key: LocalStorageKeys.ExplanationMessage })

  return {
    $trips,
    $tripId,
    $greetingMessage,
    $explanationMessage,

    updateTrips,
    updateTripId,
    updateGreetingMessage,
    updateExplanationMessage,
    resetExplanationMessage,
    resetState,
  }
})
