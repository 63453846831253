import { invoke } from '@withease/factories'
import { createEvent, sample } from 'effector'
import { atom } from '~/shared/lib/factory'
import { getTripIdeaByIdFactory } from './get-destination-by-id'
import { manageTripIdeaFactory } from './manage-destination'

export const tripIdeaModel = atom(() => {
  const resetAllState = createEvent()
  const $$manageTripIdeaFactory = invoke(manageTripIdeaFactory)
  const $$getTripIdeaByIdFactory = invoke(getTripIdeaByIdFactory, {
    $$manageTripIdeaFactory,
  })

  sample({
    clock: resetAllState,
    target: [$$manageTripIdeaFactory.resetState, $$getTripIdeaByIdFactory.resetErrors],
  })

  return {
    resetAllState,

    $$manageTripIdeaFactory,
    $$getTripIdeaByIdFactory,
  }
})
