import { RecommendTripsForRequest } from '~/entities/trip'
import { apiServiceNew, errorHandler } from '~/shared/api'

export const getTripsFromRecommender = async (
  fields: RecommendTripsForRequest,
  abortSignal: AbortSignal,
) => {
  try {
    const promiseRes = apiServiceNew.appClient.recommender.recommendTrips(fields)
    abortSignal.onabort = () => {
      if (abortSignal.aborted) {
        if (!promiseRes.isCancelled) {
          promiseRes.cancel()
        }
      }
    }
    const res = await promiseRes
    return {
      tripId: res.data?.id,
      trips: res.data?.ideas || [],
      summary: res.data?.summary || '',
      comment: res.data?.comment || '',
    }
  } catch (res: any) {
    return errorHandler(res, res.body.code)
  }
}
