import { GUEST_TYPE } from './types'

export const DEFAULT_ADULT = {
  type: GUEST_TYPE.ADULT,
  name: '',
  surname: '',
  age: 0,
}

export const DEFAULT_CHILD = {
  type: GUEST_TYPE.CHILD,
  name: '',
  surname: '',
  age: 0,
}

export const DEFAULT_ROOM = {
  adults: [DEFAULT_ADULT],
  children: [],
}

export const childAgesOptions = [
  {
    value: 0,
    label: '0',
  },

  {
    value: 1,
    label: '1',
  },

  {
    value: 2,
    label: '2',
  },

  {
    value: 3,
    label: '3',
  },

  {
    value: 4,
    label: '4',
  },

  {
    value: 5,
    label: '5',
  },

  {
    value: 6,
    label: '6',
  },

  {
    value: 7,
    label: '7',
  },

  {
    value: 8,
    label: '8',
  },

  {
    value: 9,
    label: '9',
  },

  {
    value: 10,
    label: '10',
  },

  {
    value: 11,
    label: '11',
  },
]

export const tripLimits = {
  max: { adults: 20, children: 10, rooms: 4 },
  min: { adults: 1 },
}
