import { useGate, useStore } from 'effector-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AppRoute } from '~/shared/config'
import { navigationModel } from '~/shared/models/navigation'
import { Button } from '~/shared/ui'
import { unsubscribeModel } from './model'
import { styles } from './styles'

export const UnsubscribePage = () => {
  useGate(unsubscribeModel.Gate)
  const location = useLocation()
  const loading = useStore(unsubscribeModel.$isPending)
  const message = useStore(unsubscribeModel.$message)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const email = searchParams.get('email')!
    const token = searchParams.get('token')!

    if (email && token) {
      unsubscribeModel.initiated({ email, token })
    } else {
      navigationModel.pathChanged(AppRoute.Destination)
    }
  }, [location.search])

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>Thank you</h1>
      <p css={styles.desc}>{loading ? 'Loading...' : message}</p>

      <Button
        css={styles.button}
        disabled={loading}
        onClick={() => navigationModel.pathChanged(AppRoute.Home)}
      >
        Back to Home
      </Button>
    </div>
  )
}
