import { createFactory } from '@withease/factories'
import { createEvent, restore, sample } from 'effector'
import { reset } from 'patronum'
import {
  initialServicesOptions,
  MAIN_BAGGAGE_TEXT,
  MAIN_SEAT_TEXT,
  UiElementsDataAtributeNameDuffelServiceOption,
} from '~/features/select-services/const'
import {
  changeIconToCheck,
  changeTextForSelected,
  getElementByDataTestId,
} from '~/features/select-services/lib/change-ui'
import { ManageServiceFlightDetailsFactory } from '~/features/select-services/model/manage-service-flight-details-factory'
import { ServiceOptionType } from '~/features/select-services/types'
import { REDIRECT_BOOKING_ERRORS } from '~/entities/booking/config'
import { bridge } from '~/shared/lib/factory'

export const manageUiForServicesOptionsFactory = createFactory(
  ({
    $$manageServiceFlightDetailsFactory,
  }: {
    $$manageServiceFlightDetailsFactory: ManageServiceFlightDetailsFactory
  }) => {
    const initialed = createEvent()

    const checkIfEverythingIsOk = createEvent()

    const updateEverythingIsOk = createEvent<boolean>()
    const $everythingIsOk = restore(updateEverythingIsOk, false)

    const updateError = createEvent<string>()
    const $error = restore(updateError, '')

    const resetSeatsToDefault = createEvent()

    // check if baggage is selected
    bridge(() => {
      sample({
        clock: [initialed, $$manageServiceFlightDetailsFactory.$additionalServices],
        source: {
          additionalServices: $$manageServiceFlightDetailsFactory.$additionalServices,
        },
        fn: ({ additionalServices }) => {
          additionalServices?.baggage?.totalCount > 0
            ? changeIconToCheck(
                UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.CHECK_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
              )
            : changeIconToCheck(
                UiElementsDataAtributeNameDuffelServiceOption.CHECK_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
              )
        },
      })

      // convert text
      sample({
        clock: [initialed, $$manageServiceFlightDetailsFactory.$additionalServices],
        source: {
          additionalServices: $$manageServiceFlightDetailsFactory.$additionalServices,
        },
        fn: ({ additionalServices }) =>
          additionalServices?.baggage?.totalCount > 0
            ? changeTextForSelected(
                ServiceOptionType.BAGGAGE,
                additionalServices?.baggage?.totalCount,
                additionalServices?.baggage?.totalBaggagePrice,
                UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
              )
            : changeTextForSelected(
                ServiceOptionType.BAGGAGE,
                additionalServices?.baggage?.totalCount,
                additionalServices?.baggage?.totalBaggagePrice,
                UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
                MAIN_BAGGAGE_TEXT,
              ),
      })
    })

    // seats is selected
    bridge(() => {
      // reset seats to default if seats is not selected
      sample({
        clock: $$manageServiceFlightDetailsFactory.$clickedConfirmForSeats,
        source: $$manageServiceFlightDetailsFactory.$isClickedForSelectSeat,

        filter: (isClickedForSelectSeat, clickedConfirmForSeats) =>
          !isClickedForSelectSeat && clickedConfirmForSeats,
        target: resetSeatsToDefault,
      })

      sample({
        clock: resetSeatsToDefault,
        source: $$manageServiceFlightDetailsFactory.$additionalServices,
        fn: (additionalServices) => {
          const resetSeats = initialServicesOptions.seat
          return {
            ...additionalServices,
            seat: resetSeats,
            totalCount: initialServicesOptions.baggage.totalBaggagePrice,
          }
        },
        target: $$manageServiceFlightDetailsFactory.changeAdditionalServices,
      })

      sample({
        clock: [initialed, $$manageServiceFlightDetailsFactory.$additionalServices],
        source: {
          additionalServices: $$manageServiceFlightDetailsFactory.$additionalServices,
        },
        fn: ({ additionalServices }) => {
          additionalServices.seat.totalCount > 0
            ? changeIconToCheck(
                UiElementsDataAtributeNameDuffelServiceOption.SEAT_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.CHECK_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
              )
            : changeIconToCheck(
                UiElementsDataAtributeNameDuffelServiceOption.CHECK_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.SEAT_ICON,
                UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
              )
        },
      })

      sample({
        clock: [initialed, $$manageServiceFlightDetailsFactory.$additionalServices],
        source: {
          additionalServices: $$manageServiceFlightDetailsFactory.$additionalServices,
        },
        fn: ({ additionalServices }) =>
          additionalServices?.seat?.totalCount > 0
            ? changeTextForSelected(
                ServiceOptionType.SEAT,
                additionalServices?.seat?.totalCount,
                additionalServices?.seat?.totalSeatPrice,
                UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
              )
            : changeTextForSelected(
                ServiceOptionType.SEAT,
                additionalServices?.seat?.totalCount,
                additionalServices?.seat?.totalSeatPrice,
                UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
                MAIN_SEAT_TEXT,
              ),
      })
    })

    // handle if we have Failed to load extras
    bridge(() => {
      sample({
        clock: checkIfEverythingIsOk,
        filter: () =>
          !getElementByDataTestId(
            UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
          ) &&
          !getElementByDataTestId(
            UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
          ),
        fn: () => REDIRECT_BOOKING_ERRORS.THE_OFFER_NO_LONGER_AVAILABLE,
        target: [updateError],
      })

      sample({
        clock: checkIfEverythingIsOk,
        filter: () =>
          !!getElementByDataTestId(
            UiElementsDataAtributeNameDuffelServiceOption.SEAT_WRAPPER,
          ) ||
          !!getElementByDataTestId(
            UiElementsDataAtributeNameDuffelServiceOption.BAGGAGE_WRAPPER,
          ),
        fn: () => true,
        target: updateEverythingIsOk,
      })
    })

    reset({
      clock: resetSeatsToDefault,
      target: [$error, $everythingIsOk],
    })

    return {
      initialed,
      updateError,
      resetSeatsToDefault,
      checkIfEverythingIsOk,
      updateEverythingIsOk,

      $everythingIsOk,
      $error,
    }
  },
)
