import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { Suggestion, geocodeByAddress } from 'react-places-autocomplete'
import { errorHandler, getErrorMessage } from '~/shared/api'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { LocalStorageKeys } from '~/shared/config/localstorage'
import { NEW_ISO_COUNTRY_CODES } from './config'
import { Location } from './types'

interface publicDestination {
  name: string
  countryCode: string
}

export const Gate = createGate()

export const publicDestinationSelected = createEvent<publicDestination>()
export const locationChanged = createEvent<string>()
export const locationSelected = createEvent<string>()
export const selectedLocationRemoved = createEvent<string>()
export const selectedLocationsReset = createEvent()

export const suggestionChanged = createEvent<Suggestion[]>()
export const stateReset = createEvent()

export const initializeGoogleMapsFx = createEffect(async () => {
  try {
    const { googleMapsLoader } = await import('./lib')
    await googleMapsLoader.load()
  } catch (res: any) {
    return errorHandler(res, 'Failed to initialize google maps')
  }
})

export const getCountryCodeByAddressFx = createEffect(
  async (address: string): Promise<string> => {
    try {
      const result = await geocodeByAddress(address)
      const countryComponent = result[0].address_components.find(
        (component: { types: string[] }) => component.types.includes('country'),
      )
      const countryCode =
        countryComponent?.short_name || result[0].address_components[0].short_name

      return (
        NEW_ISO_COUNTRY_CODES[countryCode as keyof typeof NEW_ISO_COUNTRY_CODES] ||
        countryCode
      )
    } catch (res: any) {
      return errorHandler(res, 'Failed to get location coordinates')
    }
  },
)

export const $locationValue = restore(locationChanged, '')
export const $selectedLocations = createStore<Location[]>([])
export const $isMaxLocationsReached = $selectedLocations.map(
  (locations) => locations.length >= 10,
)

export const $googleMapsStatus = combine({
  initialized: createStore<boolean>(false).on(initializeGoogleMapsFx.done, () => true),
  error: restore(initializeGoogleMapsFx.finally, null)
    .map(getErrorMessage)
    .reset(initializeGoogleMapsFx),
})

sample({
  clock: Gate.open,
  target: initializeGoogleMapsFx,
})

sample({
  clock: locationSelected,
  target: getCountryCodeByAddressFx,
})

sample({
  clock: getCountryCodeByAddressFx.done,
  source: $selectedLocations,
  fn: (selectedLocations, { params, result }) => {
    const newLocation = !selectedLocations.find(({ name }) => name === params)

    if (!newLocation) {
      return selectedLocations
    }

    return [
      ...selectedLocations,
      {
        name: params,
        countryCode: result,
      },
    ]
  },
  target: $selectedLocations,
})

sample({
  clock: getCountryCodeByAddressFx.done,
  fn: ({ params }) => ({
    name: eventNames.quizDestinationSelected,
    properties: {
      selected_location: params,
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: publicDestinationSelected,
  source: $selectedLocations,
  fn: (selectedLocations, { name, countryCode }) => {
    const newLocation = !selectedLocations.find(({ name }) => name === name)

    if (!newLocation) {
      return selectedLocations
    }

    return [
      ...selectedLocations,
      {
        name: name,
        countryCode: countryCode,
      },
    ]
  },
  target: $selectedLocations,
})

sample({
  clock: selectedLocationRemoved,
  source: $selectedLocations,
  fn: (selectedLocations, locationToRemove) =>
    selectedLocations.filter(({ name }) => name !== locationToRemove),
  target: $selectedLocations,
})

persist({ store: $selectedLocations, key: LocalStorageKeys.SelectedLocations })

reset({
  clock: stateReset,
  target: [$selectedLocations, $locationValue, $isMaxLocationsReached],
})

reset({
  clock: locationSelected,
  target: $locationValue,
})

reset({
  clock: selectedLocationsReset,
  target: $selectedLocations,
})
