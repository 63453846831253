import { useGate, useStore } from 'effector-react'
import { FC, Fragment, useEffect } from 'react'
import { lazily } from 'react-lazily'
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'

import { backToOnboardingNavigated } from '~/processes/onboarding-process'

import { HandleRedirect } from '~/pages/handle-redirect'
import { onboardingLayoutModel } from '~/widgets/layouts/onboarding'
import { scheduleTripDatesModel } from '~/features/onboarding/schedule-trip-dates'
import { selectBudgetModel } from '~/features/onboarding/select-budget'
import { selectLocationsModel } from '~/features/onboarding/select-locations'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import {
  modalStyles,
  modalWithLoaderStyles,
  popupModel,
  PopupType,
} from '~/entities/popup'
import { modalForgotPasswordStyles } from '~/entities/popup/ui/styles'
import { destinationModel } from '~/entities/trip'
import { tripsModel } from '~/entities/trip/model'
import { tripInfoModel } from '~/entities/trip-info'
import { viewerModel } from '~/entities/viewer'
import { analyticsModel } from '~/shared/api/analytics'
import { AppRoute, isStaging, ManageUrlQueryKeys } from '~/shared/config'
import { urlParamActions } from '~/shared/hooks'
import { navigationModel } from '~/shared/models/navigation'
import { landingPageNavigated } from '~/shared/models/navigation/model'
import { Modal } from '~/shared/ui'

import { UnsubscribePage } from './account/unsubscribe-email'
import { VerificationPage } from './account/verification-page'
import { summaryPageModel } from './onboarding/summary'
import { destinationsPageModel } from './trips'

const { AccountPage } = lazily(() => import('./account'))
const { SentryDebug } = lazily(() => import('./sentry-debug'))
const { Onboarding } = lazily(() => import('./onboarding'))
const { DestinationPage } = lazily(() => import('./destination/ui'))
const { BookingPage } = lazily(() => import('~/pages/booking/ui'))
const { DestinationsPage } = lazily(() => import('./trips/ui'))
const { DestinationDetailsPage } = lazily(
  () => import('~/pages/destinations-list/destination-details/ui'),
)
const { AllDestinationsPage } = lazily(
  () => import('~/pages/destinations-list/destinations-results/ui'),
)

const { LoginProfile } = lazily(() => import('~/features/user/login-profile/ui'))
const { SaveProfile } = lazily(() => import('~/features/user/save-profile/ui'))
const { ForgotPassword } = lazily(() => import('~/features/user/reset-password/ui'))
const { UpdatePassword } = lazily(
  () => import('~/features/user/reset-password/ui/update-password'),
)

export const Routing: FC = () => {
  const { pathname, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const landingPageUrl = queryParams.get('landingPageUrl')
  const resetOnboardingData = queryParams.get('resetOnboardingData')

  const onboardingFinished = useStore(onboardingSessionModel.$onboardingFinished)
  const bookingConfirmationFromMange = useStore(
    onboardingSessionModel.$bookingConfirmation,
  )
  const isAuthorized = useStore(viewerModel.$isAuthorized)

  const bookingConfirmation = urlParamActions().getParam(
    ManageUrlQueryKeys.BOOKING_CONFIRMATION,
  )

  const pathToDestinationsPage = useStore(
    tripsModel.$$manageNavigationForTripsFactory.$pathToDestinationsPage,
  )
  useEffect(() => {
    if (bookingConfirmation && !isAuthorized) {
      onboardingSessionModel.updateBookingConfirmation(bookingConfirmation)
    }
    if ((bookingConfirmation || bookingConfirmationFromMange) && isAuthorized) {
      navigationModel.pathChanged(
        `${AppRoute.AccountBookings}/${
          bookingConfirmation ?? bookingConfirmationFromMange
        }`,
      )
      localStorage.removeItem(ManageUrlQueryKeys.BOOKING_CONFIRMATION)
      destinationModel.openTripDetailsFromEmail()
    }
    urlParamActions().deleteParam(ManageUrlQueryKeys.BOOKING_CONFIRMATION)
  }, [])
  const navigate = useNavigate()

  useGate(navigationModel.Gate, {
    navigate,
    path: pathname,
  })

  // send page_view when location changes
  useEffect(() => {
    analyticsModel.trackPage({
      properties: { page_name: pathname },
    })

    backToOnboardingNavigated(pathname)

    onboardingSessionModel.pageChanged()
  }, [pathname])

  useEffect(() => {
    const ref = queryParams.get('ref')

    if (ref) {
      viewerModel.referralCodeUpdated(ref)
    }
  }, [])

  useEffect(() => {
    if (landingPageUrl) {
      landingPageNavigated(landingPageUrl!)
    }
  }, [])

  useEffect(() => {
    const destinationName = queryParams.get('destinationName')
    const destinationCode = queryParams.get('destinationCode')
    const isIframe = queryParams.get('isIframe')

    const resetAllOnboardingData = () => {
      destinationsPageModel.backToQuizNavigated()
      scheduleTripDatesModel.stateReset()
      selectBudgetModel.budgetReset()
      tripInfoModel.stateReset()
      destinationModel.stateReset()
      tripsModel.resetState()
      onboardingSessionModel.statusReset()
      onboardingLayoutModel.stateReset()
    }

    const redirectToInspirationPage = () => {
      navigationModel.pathChanged(AppRoute.Inspiration)
      onboardingSessionModel.stepChanged('/inspiration')
    }

    // TODO: move to model
    if (resetOnboardingData) {
      resetAllOnboardingData()
      redirectToInspirationPage()
    }

    // TODO: move to model
    if (isIframe) {
      resetAllOnboardingData()
      redirectToInspirationPage()
      onboardingSessionModel.iframeInitiated()
    }

    if (destinationName && destinationCode) {
      selectLocationsModel.publicDestinationSelected({
        name: destinationName.replace(/%/g, ' '),
        countryCode: destinationCode,
      })
    }
  }, [queryParams, resetOnboardingData])

  return (
    <Fragment>
      <Routes>
        <Route path={AppRoute.Verification} element={<VerificationPage />} />
        <Route path={AppRoute.HandleRedirect} element={<HandleRedirect />} />
        <Route path={AppRoute.UnsubscribeEmail} element={<UnsubscribePage />} />

        {/*temporary routes for list all destinations functionality */}
        {isStaging && (
          <Route path={AppRoute.DestinationsList} element={<AllDestinationsPage />} />
        )}
        {isStaging && (
          <Route
            path={`${AppRoute.DestinationsList}/:destinationCode`}
            element={<DestinationDetailsPage />}
          />
        )}

        {isStaging && <Route path={AppRoute.SentryDebug} element={<SentryDebug />} />}

        {(!onboardingFinished || resetOnboardingData) && (
          <Route path="/*" element={<Onboarding />} />
        )}

        {onboardingFinished && (
          <>
            <Route
              path={`${AppRoute.Destinations}/:tripSourceId`}
              element={<DestinationsPage />}
            />
            <Route path="/*" element={<Navigate to={pathToDestinationsPage} replace />} />
          </>
        )}

        {isAuthorized && <Route path={AppRoute.Account} element={<AccountPage />} />}
        {onboardingFinished && (
          <Route
            path={`${AppRoute.Destinations}/:tripSourceId/:destinationId`}
            element={<DestinationPage />}
          />
        )}
        {onboardingFinished && (
          <Route
            path={`${AppRoute.Destinations}/:tripSourceId/:destinationId/:hotelCode/*`}
            element={<BookingPage />}
          />
        )}

        {/* reset password popup (with connection to url)*/}
        <Route path={AppRoute.ResetPassword} element={<ResetPasswordPopup />} />
      </Routes>

      {/* popups */}
      {!isAuthorized && <Popups />}
    </Fragment>
  )
}

const Popups = () => {
  const onboardingFinished = useStore(onboardingSessionModel.$onboardingFinished)
  const visiblePopup = useStore(popupModel.$visiblePopup)
  const isNewSummaryPending = useStore(summaryPageModel.$isNewSummaryPending)
  const isTripsLoading = useStore(tripsModel.$$getRecommenderTripsFactory.$isTripsLoading)

  const popupIsOpen = (popupType: string) => visiblePopup === popupType

  const handleClose = () => {
    if (!onboardingFinished) {
      popupModel.visiblePopupChanged(PopupType.None)
    }
  }

  return (
    <Fragment>
      {/* Register popup */}

      {popupIsOpen(PopupType.Register) && (
        <Modal
          isOpen
          extendStyles={
            isTripsLoading || isNewSummaryPending
              ? { wrapper: modalWithLoaderStyles }
              : { wrapper: modalStyles }
          }
        >
          <SaveProfile />
        </Modal>
      )}

      {/* Login popup */}
      {popupIsOpen(PopupType.Login) && (
        <Modal
          isOpen
          extendStyles={
            isTripsLoading || isNewSummaryPending
              ? { wrapper: modalWithLoaderStyles }
              : { wrapper: modalStyles }
          }
          onClose={handleClose}
        >
          <LoginProfile />
        </Modal>
      )}

      {/* Forgot password popup */}
      {popupIsOpen(PopupType.ForgotPassword) && (
        <Modal
          isOpen
          extendStyles={{ wrapper: modalForgotPasswordStyles }}
          onClose={handleClose}
        >
          <ForgotPassword />
        </Modal>
      )}
    </Fragment>
  )
}

const ResetPasswordPopup = () => {
  const isAuthorized = useStore(viewerModel.$isAuthorized)

  return (
    <>
      <DestinationsPage />
      <Modal
        extendStyles={{ wrapper: modalStyles }}
        onClose={() =>
          isAuthorized
            ? tripsModel.$$manageNavigationForTripsFactory.navigateToDestinationsPage()
            : null
        }
        isOpen
      >
        <UpdatePassword />
      </Modal>
    </>
  )
}
