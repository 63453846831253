import { createFactory } from '@withease/factories'
import { sample } from 'effector'
import { GetRecommenderTripsFactory } from '~/entities/trip/model/get-recommender-trips'
import { analyticsModel, eventNames } from '~/shared/api/analytics'

export const tripsAnalyticsFactory = createFactory(
  ({
    $$getRecommenderTripsFactory,
  }: {
    $$getRecommenderTripsFactory: GetRecommenderTripsFactory
  }) => {
    let startTime = 0

    sample({
      clock: $$getRecommenderTripsFactory.getTripsFx,
      fn: () => {
        startTime = performance.now()

        return {
          name: eventNames.quizLoadingVisited,
        }
      },
      target: analyticsModel.track,
    })

    sample({
      clock: $$getRecommenderTripsFactory.getTripsFx.doneData,
      fn: () => {
        const endTime = performance.now()
        const responseTimeInSeconds = (endTime - startTime) / 1000
        const responseTimeFormatted = responseTimeInSeconds.toFixed(2)

        return {
          name: eventNames.quizLoadingCompleted,
          properties: {
            timespent: `${responseTimeFormatted} seconds`,
          },
        }
      },
      target: analyticsModel.track,
    })
  },
)
