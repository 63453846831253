import {
  ChangeTripsDatesRequestDto,
  RecommendTripsResponseDto,
} from '@softcery/awayaway-nodejs-api-client'
import { apiServiceNew, apiErrorHandler } from '~/shared/api'

export const updateTripsData = async ({
  tripResourceId,
  requestBody,
}: {
  tripResourceId: string
  requestBody: ChangeTripsDatesRequestDto
}): Promise<RecommendTripsResponseDto> => {
  try {
    return await apiServiceNew.appClient.tripsRecommendations.changeTripsDates(
      tripResourceId,
      requestBody,
    )
  } catch (e) {
    return apiErrorHandler(e)
  }
}
