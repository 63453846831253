import { invoke } from '@withease/factories'
import { createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { or, reset } from 'patronum'
import { getUserPositionAirportFactory } from '~/features/search-flights/model/get-user-position'
import { searchFlightsFactory } from '~/features/search-flights/model/search-flights'
import { searchFlightsOptionFactory } from '~/features/search-flights/model/search-flights-option'
import { searchPlacesFactory } from '~/entities/flights/model'
import { atom } from '~/shared/lib/factory'
import { DefaultValues } from '../types'
import { flightsAnalyticsFactory } from './analytics'
import { manageLocalStorageForSearchFlightsFactory } from './'

interface Dates {
  startDate: Date
  endDate: Date
}

export const searchFlightsModel = atom(() => {
  const Gate = createGate<DefaultValues>()

  const stateReset = createEvent()

  // check if we need it
  const datesChanged = createEvent<Dates>()

  // todo do nothing
  const outboundOfferChangeInitiated = createEvent()
  const inboundOfferChangeInitiated = createEvent()
  const resetPreviousOffer = createEvent()
  const flightsConfirmed = createEvent()

  // todo --------

  const $$getUserPositionFactory = invoke(getUserPositionAirportFactory)

  const $$manageLocalStorageForSearchFlights = invoke(
    manageLocalStorageForSearchFlightsFactory,
  )

  const $$searchFlightsFactory = invoke(searchFlightsFactory, {
    gate: Gate,
    $$manageLocalStorageForSearchFlights,
    $$getUserPositionFactory,
  })

  const $$searchFlightsOptionFactory = invoke(searchFlightsOptionFactory, {
    $$manageLocalStorageForSearchFlights: $$manageLocalStorageForSearchFlights,
  })

  const $$searchPlacesFactory = invoke(searchPlacesFactory)

  invoke(flightsAnalyticsFactory, {
    searchFlightsFactory: $$searchFlightsFactory,
    manageLocalStorageForSearchFlights: $$manageLocalStorageForSearchFlights,
    searchFlightsOptionFactory: $$searchFlightsOptionFactory,
  })

  const $loading = or(
    $$searchFlightsFactory.$loading,
    $$searchFlightsOptionFactory.$loading,
  )
  const $error = createStore('')

  sample({
    clock: [$$searchFlightsFactory.searchFlightsFx.doneData],
    fn: (res) => res.data?.availableOptions || [],
    target: [
      $$manageLocalStorageForSearchFlights.updateOptions,
      $$manageLocalStorageForSearchFlights.updateRecommendedOptions,
    ],
  })

  sample({
    clock: Gate.open,
    source: $$searchFlightsFactory.$fromAirport,
    filter: (fromAirport) => !fromAirport,
    target: $$getUserPositionFactory.getUserNearestAirportFx,
  })

  sample({
    clock: [$$searchFlightsOptionFactory.searchFlightsOptionFx.doneData],
    fn: (res) => res.data?.availableOptions || [],
    target: $$manageLocalStorageForSearchFlights.updateOptions,
  })

  sample({
    clock: [$$searchFlightsFactory.$error, $$searchFlightsOptionFactory.$error],
    target: $error,
  })

  reset({
    clock: [
      $$searchFlightsFactory.searchFlightsFx,
      $$searchFlightsOptionFactory.searchFlightsOptionFx,
    ],
    target: $error,
  })

  reset({
    clock: $$searchFlightsFactory.searchFlightsFx,
    target: $$searchFlightsOptionFactory.$flightsOption,
  })

  sample({
    clock: stateReset,
    target: [
      $$manageLocalStorageForSearchFlights.resetManageLocalStorageForSearchFlights,
      $$searchFlightsFactory.resetChildrenCount,
      $$searchFlightsFactory.resetAdultsCount,
    ],
  })

  return {
    Gate,

    stateReset,
    datesChanged,
    outboundOfferChangeInitiated,
    inboundOfferChangeInitiated,
    resetPreviousOffer,
    flightsConfirmed,

    $loading,
    $error,

    $$manageLocalStorageForSearchFlights,
    $$searchFlightsOptionFactory,
    $$searchFlightsFactory,
    $$searchPlacesFactory,
    $$getUserPositionFactory,
  }
})
