import * as process from 'node:process'
import { sample } from 'effector'

import { createEffect } from 'effector/compat'
import { once } from 'patronum'
import { viewerModel } from '~/entities/viewer'
import { analyticsModel } from '~/shared/api/analytics'
import { googleAnalytics } from '~/shared/api/analytics/google'
import { appStarted } from '~/shared/config/init'

const mixpanelLazyLoad = createEffect(async () => {
  const { mixpanelAnalytics } = await import('~/shared/api/analytics/mixpanel')
  console.log('mixpanelAnalytics', mixpanelAnalytics)
  analyticsModel.init({
    analytics: mixpanelAnalytics,
    key: process.env.AA_MIXPANEL_TOKEN,
  })
})
const appStartedOnce = once({ source: appStarted })
//init analytics
sample({
  clock: appStartedOnce,
  filter: () => Boolean(process.env.AA_MIXPANEL_TOKEN),
  target: mixpanelLazyLoad,
})

const hotjarLazyLoad = async () => {
  const { hotjarAnalytics } = await import('~/shared/api/analytics/hotjar')
  analyticsModel.init({
    key: process.env.AA_HOTJAR_ID,
    analytics: hotjarAnalytics,
  })
}
let isHotjarLoaded = false
const loadHotjarOnce = () => {
  if (isHotjarLoaded) return

  isHotjarLoaded = true
  document.removeEventListener('scroll', loadHotjarOnce)
  document.removeEventListener('click', loadHotjarOnce)

  if (Boolean(process.env.AA_HOTJAR_ID)) {
    hotjarLazyLoad()
  }
}

document.addEventListener('scroll', loadHotjarOnce)
document.addEventListener('click', loadHotjarOnce)

setTimeout(() => {
  loadHotjarOnce()
}, 5000)

sample({
  clock: appStarted,
  filter: () => Boolean(process.env.AA_GTAG_ID),
  fn: () => ({
    analytics: googleAnalytics,
  }),
  target: analyticsModel.init,
})

//identify user
sample({
  clock: appStarted,
  source: viewerModel.$viewer,
  filter: (viewer) => Boolean(viewer.email),
  fn: (viewer) => ({ id: String(viewer.email) }),
  target: analyticsModel.identify,
})

//track initial page view
sample({
  clock: appStarted,
  fn: () => ({
    properties: { page_name: window.location.pathname },
  }),
  target: analyticsModel.trackPage,
})
